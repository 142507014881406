import env from "./Vars";
import { initializeApp } from "firebase/app";
import { getAuth, getRedirectResult } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage  } from "firebase/storage";

class Config {
    constructor() {
        this.env = env;
        console.log(this.env)
        this.apiHost = this.env.api.host;
        this.apiKey = this.env.headers.Authorization;
        this.headers = this.env.headers;
        this.fApp = initializeApp(this.env.firebase.config);
        this.fAuth = getAuth(this.fApp);
        this.db = getFirestore(this.fApp);
        this.storage = getStorage(this.fApp);
        this.query = this._queryString();
        this.prefix = (str) => { return env.database.prefix + str; };
        this.user = false;
    }

    

    authUser = async () => {
      return getRedirectResult(this.fAuth).then(()=>{
        this.user = this.fetchUserInfo(this.fAuth.currentUser);
        return this.user;
      }).catch((err)=>{
        
      });
    }

    fetchUserInfo = async (user) => {
      if(user && user !== null){
          const token = user.accessToken;
          //
          window.localStorage.setItem('uid', user.uid);
          const result = await this.postReq(token, "/get",{ post: {
                  action: 'findone',
                  collection: this.prefix('users'),
                  qry: { _id: user.uid }
          }},'application/json');
          
          if(result.ok){
              
            const data = await result.json();
            //
            if(data !== null){
              user.info = data;
              //_user.uid = window.localStorage.getItem('uid');
              //_user.info = data;
             // _user.session = user;
            }
            return user;
              
          }
          
      }else{
          return user;
      }
           
     
    }

    _queryString = (qry = '') => {
        const params = new URLSearchParams(window.location.search);
        if(qry === ''){
          const result = {}
          for(const [key, value] of params.entries()) { // each 'entry' is a [key, value] tupple
            result[key] = value;
          }
          return result;
        }else{
          return params.get(qry);
        }
        
      }

      checkLogin(){
        if(this.user){
            window.localStorage.setItem('uid', this.user.uid);
        }
        this.isLogin = localStorage.getItem('uid') !== null ? true : false;
      }
  
      isLogin = (user = false, crole = false) => {
        user = !user ? this.user : user;
        let login = user && user !== -1;
        let urole = login && user.info && user.info.role ? user.info.role : false;
        if(!crole){
          return login ? urole : false;
        }else{
          
          return login && (urole === crole.toLowerCase());
        }
        
      }
  
      isAdmin(){
        //
        if(this.user === null){return false;}
        if(this.user.uid === null || this.user.info === null){
          return false;
        }
          return true;
      }

      fError(err){
        switch(err){
          case 'Firebase: Error (auth/wrong-password).':
            return 'Password is wrong';
          case 'Firebase: Error (auth/user-not-found).':
            return 'User is not available!';
          case 'Firebase: Error (auth/invalid-action-code).':
            return 'Authentication code is expired or invalid! Get New link from the login again and try to login with new link.';
          default:
            return err;
        }
    }

}

export default Config;