import * as React from 'react';
import { Helmet } from "react-helmet";
//import DataTable from "react-data-table-component";

import '../../assets/vendor/datatables/dataTables.bootstrap4.min.css';

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: false,
            load: 'Loading...'
        }
        this.getUsers();

    }

    filterData = (dataset = [], args = []) => {

        let arr = [];
        if (Array.isArray(dataset)) {
            let obj;
            let cname;
            dataset.map((x) => {
                obj = {};
                args.forEach((a) => {
                    cname = a.cname.split('.');
                    obj[`${cname.join('_')}`] = x[cname[1] !== undefined ? cname[1] : cname[0]]

                });
                arr.push(obj);
                return arr;
            });

        }
        return arr;
    }




    getUsers = async () => {
        let token = this.props.user.accessToken;
        const users = await this.props.app.listAllUsers(token);
        
        this.columns = [
            {
                cname: 'uid',
                name: 'UID',
                selector: row => row.uid,
                sortable: false,
            },
            {
                name: 'Display Name',
                cname: 'displayName',
                selector: row => row.displayName,
                sortable: true,
            },
            {
                name: 'Email',
                cname: 'email',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'Email Verfied',
                cname: 'emailVerified',
                selector: row => row.emailVerified,
                sortable: true,
            },
            {
                name: 'Accound Disabled',
                cname: 'disabled',
                selector: row => row.disabled,
                sortable: true,
            },
            {
                name: 'Last Login',
                cname: 'metadata.lastSignInTime',
                selector: row => row.metadata.lastSignInTime,
                sortable: true,
            },
            {
                name: 'Created',
                cname: 'metadata.creationTime',
                selector: row => row.metadata.creationTime,
                sortable: true,
            }
        ];
        
        this.setState({
            users: users,
            load: users.length > 0 ? '' : 'No Data Found!'
        });
    }

    render() {

        return (
            <>
                <Helmet>
                    <title>Users</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />


                    <body id="page-top"></body>
                </Helmet>
                <h1 className="h3 mb-2 text-gray-800">Users</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">User List</h6>
                    </div>
                    <div className="card-body">

                        {
                            this.state.users !== undefined && (this.state.users.length > 0) ?
                                (
                                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th className='text-center'>DisplayName</th>
                                                <th className='text-center'>Email</th>
                                                <th className='text-center'>Uid</th>
                                                <th className='text-center'>Login Mode</th>
                                                <th className='text-center'>Last Login</th>
                                                <th className='text-center'>Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.users.map((user, index) => {

                                                    return (
                                                        <tr key={index}>
                                                            <td className='text-center'>{index + 1}</td>
                                                            <td className='text-center'>{user.displayName === undefined ? 'Not Set' : user.displayName}</td>
                                                            <td className='text-center'>
                                                                {user.email} {user.disabled ? <i className="fa text-danger fa-ban" title='User Disabled' aria-hidden="true"></i> : <></>} {user.emailVerified ? <i className="fa text-success fa-check-circle" title='Email Verified' aria-hidden="true"></i> : <></>}
                                                            </td>
                                                            <td className='text-center'>{user.uid}</td>
                                                            <td className='text-center'>{user.providerData[0].providerId}</td>
                                                            <td className='text-center'>{user.metadata.lastSignInTime}</td>
                                                            <td className='text-center'>{user.metadata.creationTime}</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                ) :
                                (<>{this.load}</>)
                        }

                    </div>
                </div>
            </>
        )
    }

}

export default Users