import * as React from 'react';
import QrReader from 'react-qr-scanner';
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { Link, useParams } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class BookingNew extends React.Component{

    constructor(props){
        super(props);
        this.bookingData = window.localStorage.getItem('bookingData');
        this.bookingData = this.bookingData === null || this.bookingData.length < 1 ? '[]' : this.bookingData;
        this.bookingData = JSON.parse(this.bookingData);
        
        this.state = {
            booking: this.bookingData,
            selectedDate: this.props.app.getDate(),
            selectedSlot: this.props.app.getDate(`h:00`, false, {minute: 60}),
            person: {},
            update: false,
            people: [],
            bkdata: {}
        }
        if(this.props.params.id){
            this.getTicket(this.props.params.id);
        }
    }

    async componentDidMount(){
        await this.checkslots();
    }
    
    checkslots = async () => {
        let bkslots = await this.props.app._getmongo(this.props.user.accessToken, 'findone','AvailableSlots', {_id: this.state.selectedDate + ' ' + this.state.selectedSlot});
        if(bkslots.ok){
            bkslots = await bkslots.json();
        }
        if(bkslots === null) {
            let cnf = window.prompt("Slot is not available on given data and time, Would you like to add a slot?");
            if(cnf !== null && cnf !== ''){
                this.addSlot(cnf);
            }
           // console.log(cnf);
        }
        console.log(this.state.selectedDate + ' ' + this.state.selectedSlot);
    }

        addSlot = async(e) => {
            
            //const { slotdate, slotdatetill, slottime, capacity } = e.target.elements;
            
            let token = this.props.user.accessToken;
    
            const dates = [this.state.selectedDate];
            const cap = e;
            let data = [];
            let idx = 0;
            var slots = [this.state.selectedSlot];
            dates.forEach((dt, index) => {
                slots.forEach((x, i) => {
                    var _k = dt + ' ' + x;
                    data[idx] = {
                        _id: _k,
                        Date: dt,
                        Slot: x,
                        Available: parseInt(cap),
                        Booked: 0,
                        checkIn: 0,
                        checkOut: 0
                    }
                    idx++;
                })
            });
            try{
                let res = await this.props.app._setmongo(token, 'uniqueinsert', 'AvailableSlots', data);
                console.log(res);
            }catch(ex){
                console.log(ex)
            }
    
            //this.fetchdata();
        }
    

    getTicket = async (id) => {
        let ticket = await this.props.app._getmongo(this.props.user.accessToken, 'findone','bookings', {_id: {'ObjectId': id}});
        if(ticket.ok){
            window.localStorage.removeItem('bookingData');
            ticket = await ticket.json();
            this.setState({
                update: ticket
            });
            let dateslot = ticket.bookingslot.split(' ');
            document.getElementById('selecteddate').value = dateslot[0] !== undefined ? dateslot[0] : this.props.app.getDate();
            document.getElementById('slot').value = dateslot[1] !== undefined ? dateslot[1] : this.props.app.getDate(`h:00`, false, {minute: 60});
            let people = await this.props.app._getmongo(this.props.user.accessToken,'find','bookingPeople',{fid: ticket._id});
            let pdata = [];
            if(people.ok){
                people = await people.json();
                this.setState({
                    people: people,
                    bkdata: {slot: ticket.slot, date: ticket.date}
                });
                /*people.forEach((x)=>{
                    pdata.push({
                        slot: ticket.slot, date: ticket.date,
                        people: {
                            pname: x.pname,
                            gender: x.gender,
                            dob: x.dob,
                            nationality: x.nationality,
                            dno: x.dno,
                            _id: x._id
                        }
                    });
                });*/
                this.bookingData = pdata;
                window.localStorage.setItem('bookingData', JSON.stringify(this.bookingData));
                this.setState({
                    booking: this.bookingData,
                    selectedDate: ticket.date,
                    selectedSlot: this.props.app.getDate(ticket.slot, false, {minute: 60}),
                    person: {}
                });
                
            }
        }
    }

    parseAadhar = async (e) => {
        let text = typeof(e) === 'object' && e.target !== undefined ? e.target.value : e;
        text= text.replace(new RegExp(/.+?(\?xml.*\?)\\>\s/gm, 'gm'),'').trim();
        let parser = new DOMParser();
        let xmlDoc;
        if(text.length > 12){
            try{
                xmlDoc = parser.parseFromString(text,"text/xml");
                
                var attrs = xmlDoc.getElementsByTagName('PrintLetterBarcodeData');
                
                if(attrs.length > 0){
                    attrs = Object.fromEntries(Array.from(attrs[0].attributes).map(item => [item.name, item.value]));
                    
                    
                    attrs.dob = this.props.app.formatAnyDate(attrs.dob);
                    
                    const person = {
                        pname: attrs.name,
                        dob: attrs.dob,
                        gender: attrs.gender,
                        nationality: 'IN',
                        dno: attrs.uid
                    }
                    this.setState({
                        person: person
                    });
                    
                }
                
                return attrs;
            }catch(ex){
                this.setState({
                    person: {
                        pname: '',
                        dob: '',
                        gender: '',
                        nationality: '',
                        dno: e.target.value !== '' ? e.target.value : Math.random().toString(36).slice(2)
                    }
                });
                
            }
        }
    }

    handleScan = async (data) => {
        if(data !== null){
            
            if(this.state.id !== data.text)
            {
                this.setState({
                    qrcode: data.text
                });
                this.parseAadhar(data.text);
                
                //await this.getpersoninfo(data.text);
                var beepSound = new Audio('./assets/beep.mp3');
                beepSound.loop = false;
                beepSound.play();
            }
            
        }
    }

    

    handleError(err){
        console.log(err)
    }
      

    formControl = (id, label, type, obj = {}, options = [], classname = 'form-control') => {
        let ctrl;
        switch(type){
            case 'select':
                ctrl = () => {
                    return <div className='form-group'>
                        <label htmlFor={id}>{label}</label>
                        <select name={id} id={id} className={classname} {...obj} >
                            {options.map((x,i)=>{
                                return <option key={i} index={i} value={x.value}>{x.text}</option>
                            })}
                        </select>
                    </div>
                }
            break;
            default:
                type = type === 'datetime' ? 'datetime-local' : type;
                ctrl = () => {
                   return  <div className='form-group'>
                        <label htmlFor={id}>{label}</label>
                        <input type={type} name={id} id={id} className={classname} {...obj} />
                    </div>
                }
            break;
        }
        return ctrl();
    }

    showPicker = (e) => {
        e.preventDefault();
        const el = e.target;
        if (el.getAttribute("type")==="date" || el.getAttribute("type")==="time" || el.getAttribute("type")==="datetime-local") {
            el.showPicker();
        }
    }

    fixDate = (e) => {
        let val = e.target.value;
        
        this.setState({
            selectedDate: val
        });
    }

    fixSlot = (e) => {
        let val = e.target.value;
        let step = e.target.getAttribute('interval');
        val = val.split(':');
        
        if(val !== undefined && val[1] !== undefined && step !== undefined){
            val[0] = parseInt(val[0]);
            val[1] = parseInt(val[1]);
            step = parseInt(step);
            let diff = val[1] === 0 || val[1] < step ? step : ((Math.floor(val[1] / step) * step) + step);
            val[1] = diff;
            if(diff === 60){
                val[0]++;
                val[1] = '00';
            }
            val[0] = val[0] >= 24 ? 24 - val[0] : val[0];
            val[0] = val[0] < 10 ? '0' + val[0] : val[0];
            e.target.value = val.join(':');
            
            this.setState({
                selectedSlot: e.target.value
            });
        }
    }

    editPerson = (e) => {
        try{
            var json = JSON.parse(e.target.getAttribute('data-json'));
            json = json === null ? JSON.parse(e.target.parentElement.getAttribute('data-json')) : json;
            Object.entries(json).forEach((x)=>{
                if(document.getElementById(x[0]) !== null){
                    document.getElementById(x[0]).value = x[1];
                }
            })
            
        }catch(ex){
            console.log(ex)
        }
    }

    addPerson = async (e) => {
        e.preventDefault();
        const ele = e.target.elements;
        
        await this.parseAadhar(ele.dno.value);

        let pdata = {
            pname: ele.pname.value,
            gender: ele.gender.value,
            dob: ele.dob.value,
            nationality: ele.nationality.value,
            dno: ele.dno.value === '' ? Math.random().toString(36).slice(2) : ele.dno.value
        };
        
        // eslint-disable-next-line array-callback-return
        let found = this.bookingData.map((x,i) => {
            if(x.dno === ele.dno.value){
                return i;
            }
        });

        let alert = "New Person Added!";
        found.forEach((fn)=>{
            if(fn !== undefined){
                this.bookingData.splice(fn,1);
                alert = "Person's Details Updated!";
            }
        });
        
        this.bookingData.push(pdata);
        window.localStorage.setItem('bookingData', JSON.stringify(this.bookingData));
        this.setState({
            booking: this.bookingData,
            person: {}
        });
        ele.pname.value = ''; ele.gender.value = ''; ele.nationality.value = ''; ele.dno.value = '';
        
        //alert = 'Max allowed entries per slot has been exceeded! Please select another slot or do separate booking for other people in different slot';
        
        
        window.alert(alert);
        
    }

    dispose = (e) => {
        let confirm = window.confirm('Are you sure to discard this booking?');
        if(confirm){
            window.localStorage.removeItem('bookingData');
            this.bookingData = window.localStorage.getItem('bookingData');
            this.bookingData = this.bookingData === null || this.bookingData.length < 1 ? '[]' : this.bookingData;
            this.bookingData = JSON.parse(this.bookingData);
            this.setState({
                booking: this.bookingData
            })
        }
    }

    cancel = async (e) => {
        const mobile  = window.prompt('Enter registered booking mobile number or 12345!');
        if(!isNaN(mobile) && mobile.length > 4){
                if(mobile === '12345' || mobile === this.state.update.mobile){
                    let token = this.props.user.accessToken;
                    let resp = await this.props.app._setmongo(token, 'findoneupdate', 'bookings', {"$set": {"status": "-2"}},{_id: {'ObjectId': this.state.update._id}});
                    if(resp.ok){
                        resp = await resp.json();
                        
                        window.location.reload();
                    }
                }else{
                    window.alert('Mobile Number did not matched');
                }
        }else{
            window.alert('Invalid Mobile Number');
        }
    }
    
    
    removePerson = async (e) => {
        let confirm = window.confirm('Are you sure you want to remove the person: ');
        if(confirm){
            const index = e.target.getAttribute('data-index');
            let booking = this.state.booking;
            if(Array.isArray(booking)){
                delete(booking[index]);
                if(booking.length < 1){
                    booking = undefined;
                }
            }
            this.setState({
                booking: booking
            });
            window.localStorage.setItem('bookingData',booking);
            
        }
    }
    cancelRemove = async (e) => {
        const index = e.target.getAttribute('data-index');
        let booking = this.state.booking;
        delete(booking[index]['del']);
        this.setState({
            booking: booking
        });
        
        
    }

    savenow = async (e) => {
        const mobile  = window.prompt('Enter registered booking mobile number or 12345!');
        if(!isNaN(mobile) && mobile.length > 4){
           
            //let fdata = window.localStorage.getItem('formdata');
           
            //fdata = fdata !== null ? JSON.parse(fdata) : {};
            let bookingData = {
                slot: this.state.selectedSlot,
                date: this.state.selectedDate,
                people: this.state.booking,
                mobile: mobile
            };


            
            
            if(mobile === '12345' || mobile === this.state.update.mobile){
                let booking = this.state.booking;
                booking.id = this.state.update._id;
                let token = this.props.user.accessToken;
                let resp = await this.props.app.processBooking(token, bookingData, this.state.update._id);
                
                if(resp.ok){
                    
                }
                this.setState({
                    booking: booking
                })
            }
        }
    }

   

    addQuota = async() => {
        let e = window.prompt('Booking Quota is full for this slot. Do you want to increase the quota? 0 will auto increase quota.')
        //e = parseInt(e);
        console.log(e);
        if(e !== null && e.length > 0){
           if(!isNaN(e)){
                let add = e < 1 ? this.state.booking.length : parseInt(e);
                let token = this.props.user.accessToken;
                let slot = this.state.selectedDate + ' ' +this.state.selectedSlot;
                console.log(slot);
                let resp = await this.props.app._setmongo(token, 'updateorinsert','AvailableSlots',{ 
                    $set: {Slot: this.state.selectedSlot, Date: this.state.selectedDate},
                    $inc: { "Available": add, "Booked": 0 }
                },{_id: slot});
                if(resp.ok){
                    resp = await resp.json();
                    console.log(resp);
                
                }
                if(resp.upsertedId === this.state.selectedDate + ' ' +this.state.selectedSlot){

                    alert(`New ${add} slots have been added to continue booking proceed by clicking book now button again`);
                }
            
       } else{
            this.addQuota();
           }
        }
    }

    isISD = (n) => {
        
        var reg = new RegExp(/^[+][0-9]+[-]+[0-9]{4,14}$/g);
        return reg.test(n);
    }
    toISD = (n, d = '+91-') => {
        
        if(!this.isISD(n)){
            n = d + n;
            
            if(!this.isISD(n)){
                return;
            }        
        }
        
        return n
    }

    booknow = async (e) => {
        e.preventDefault();
        const mobile  = window.prompt('Enter mobile number, ISD Number must be +(ISD_CODE)-(Number) format. For E.G: US number +1-987626222');
        if(this.isISD(mobile) || (mobile.length > 4 && Number.isInteger(parseInt(mobile)))){
            //const country = '91';
            //mobile = this.toISD(mobile);
            let pfm = this.toISD(mobile);
            console.log(pfm)
            if(pfm === undefined){
                alert('ISD Number must be +(ISD_CODE)-(Number) format. For E.G: US number +1-987626222');
            }else{
                let token = this.props.user.accessToken;
                let booking = this.state.booking;
                //booking.id = this.state.update._id;
                let bookingData = {
                    slot: this.state.selectedSlot,
                    date: this.state.selectedDate,
                    people: this.state.booking,
                    mobile: pfm
                };
                let resp = await this.props.app.processBooking(token, bookingData, this.state.update._id);
                
                
                this.setState({
                    booking: booking
                })
                let result = false;
                if(resp.ok){
                    result = await resp.json();
                }
                if(result){
                    if(result.res === 'success'){

                        window.localStorage.removeItem('bookingData');
                    
                        alert('Mobile Verified & Booking Done!');
                        window.location.href = window.location.protocol + '//' + window.location.host + '/booking/' + result.id;
                    }else{
                        if(result.id > 0){
                            this.addQuota();
                            
                        }else{
                            alert(result.msg);
                        }
                    }
                }else{
                    console.log(resp.status);
                    if(resp !== undefined && resp.status === 403){
                        alert('Session Expired! Click ok to refresh the page!');
                        window.location.reload();
                    }else{
                        alert('Booking Failed! Contact Administrator!');
                    }
                    
                }
            }
            
            
            
        }else{
            alert('Invalid Mobile Number')
        }
        //
    }
    

    render(){
        const previewStyle = {
            height: '100%',
            width: '100%',
          }
        return (<>
            <Helmet>
                <title>Add New Booking</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <body id="page-top"></body>
            </Helmet>
            <div className='qrscanner d-none do-not-print'>
            <div className="QRtv">
                <button className='btn btn-secondary btn-hide' onClick={this.toggleTV}>{this.state.toggleTVtext}</button>
                <div className={this.state.toggleTVtext === 'Show Screen' ? 'tv d-none' : 'tv'}>

                <QrReader
                    delay={this.state.delay}
                    style={previewStyle}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    constraints={{
                        video: {
                            facingMode: {
                            exact: isMobile ? "environment" : "user"
                            }
                        }
                    }}
                />
                </div>
            </div>
            
        </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className='row'>
                        <div className="col-md-9">
                            <h1 className="h3 mb-2 text-gray-800">{!this.state.update ? 'Add New Booking' : `Updating Ticket# ${this.state.update._id}`}</h1>
                        </div>
                        <div className='col-md-3 text-right'>
                        <Link to='/bookings' className='btn btn-primary'>Show All</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.addPerson}>
                        <div className='row'>
                            <div className='col-md-3'>
                                {this.formControl('selecteddate', 'Booking Date', 'date',{
                                    placeholder: 'Booking Date',
                                    required: 'required',
                                    onChange: this.fixDate,
                                    onClick: this.showPicker,
                                    min: !this.state.update ? this.props.app.getDate() : '',
                                    defaultValue: this.state.update !== false ? this.state.update.date : this.props.app.getDate(),
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>
                            <div className='col-md-3'>
                                {this.formControl('slot', 'Booking Slot', 'time', {
                                    placeholder: 'Booking Slot',
                                    required: 'required',
                                    onChange: this.fixSlot,
                                    onClick: this.showPicker,
                                    interval: 15,
                                    defaultValue: !this.state.update ? this.props.app.getDate(`h:00`, false, {minute: 60}) : this.state.update.slot,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>
                            
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                {this.formControl('pname', 'Person Name', 'text',{
                                    'placeholder': 'Person Name',
                                    required: 'required',
                                    value: this.state.person.pname,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>
                            <div className='col-md-2'>
                                {this.formControl('dob', 'Date of Birth', 'date', {
                                    'placeholder': 'Date of Birth',
                                    required: 'required',
                                    onFocus: this.showPicker,
                                    max: this.props.app.getDate('Y-M-D', false, {year: -5}),
                                    value: this.state.person.dob,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>
                            <div className='col-md-1'>
                                {this.formControl('gender', 'Gender', 'select', {
                                    required: 'required',
                                    value: this.state.person.gender,
                                    disabled: this.state.update.status === '-2' ? true : false
                                },[
                                    {text: 'Male', value: 'M'},
                                    {text: 'Female', value: 'F'},
                                    {text: 'Other', value: 'O'},
                                ])}
                            </div>
                            <div className='col-md-2'>
                                {this.formControl('nationality', 'Nationality', 'select', { required: 'required', value: this.state.person.nationality, disabled: this.state.update.status === '-2' ? true : false },[
                                    {text: 'Indian', value: 'IN'},
                                    {text: 'Other', value: 'OTHER'}
                                ])}
                            </div>
                            <div className='col-md-3'>
                                {this.formControl('dno', 'Document Number', 'text', {
                                    placeholder: 'Aadhaar Number If Indian Otherwise Passport. Check passport expiry',
                                    onChange: this.parseAadhar,
                                    onBlur: this.parseAadhar,
                                    value: this.state.person.dno,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>

                        </div>
                        <div className='row'>
                        <div className='col-md-12 text-right'>
                                <button type='submit' className='btn btn-primary' disabled={this.state.update.status === '-2' ? true : false}>Add Person</button>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-md-12'>
                            
                                {
                                    Array.isArray(this.state.booking) && this.state.booking.length > 0 ? 
                                    <div className='table-responsive'>
                                        <hr />
                                        <div className='row'>
                                            
                                                {
                                                    this.state.update.status === '-2' || this.state.update.status > 1 ? <><span></span></> :
                                                    <>
                                                    <div className='col-md-6 col-6'>
                                                        {this.state.update ?
                                                        <><button type='button' className='btn btn-danger' data-id={this.state.update._id} onClick={this.cancel}>Cancel Booking</button></>
                                                        : <button type='button' className='btn btn-danger' onClick={this.dispose}>Discard Booking</button>}
                                                    </div>
                                                    <div className='col-md-6 col-6 text-right'>
                                                        {this.state.update ?
                                                        <><button type='button' className='btn btn-success' onClick={this.savenow}>Save Now</button></>
                                                        :<button type='button' className='btn btn-success' onClick={this.booknow}>Book Now</button>}
                                                        
                                                    </div>
                                                    </>
                                                }
                                            
                                        </div>
                                        <hr />
                                        <small>Double Click on table row to edit</small>
                                        <table className='table table-hover'>
                                            <thead>
                                                <tr>
                                                    <td>Person</td>
                                                    <td>Dob</td>
                                                    <td>Gender</td>
                                                    <td>Nationality</td>
                                                    <td>Document ID</td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.booking.map((x,i)=>{
                                                        
                                                        return (<tr key={i} onDoubleClick={this.editPerson} data-json={JSON.stringify(x)} style={x.del !== undefined ? {textDecoration: 'line-through'}: {}}>
                                                                <td>{x.pname}</td>
                                                                <td>{x.dob}</td>
                                                                <td>{x.gender}</td>
                                                                <td>{x.nationality}</td>
                                                                <td>{x.dno}</td>
                                                                <td className='text-right'>
                                                                    {
                                                                        this.state.update.status === '-2' || this.state.update.status > 1 ? <><span></span></> :
                                                                        <>
                                                                        {
                                                                        x.del ? 
                                                                        <button data-index={i} data-id={(x._id !== undefined ? x._id : i)} id={'p_' + (x._id !== undefined ? x._id : i)} onClick={this.cancelRemove} className="btn btn-danger" title='Cancel Delete'>Cancel</button>
                                                                        :<button data-index={i} data-id={(x._id !== undefined ? x._id : i)} id={'p_' + (x._id !== undefined ? x._id : i)} onClick={this.removePerson} className="btn btn-danger" title='Delete'>Delete</button>
                                                                        }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>)
                                                    })
                                                }
                                                
                                            </tbody>
                                        </table>
                                        <hr />
                                        <div className='row'>
                                        {
                                                    this.state.update.status === '-2' || this.state.update.status > 1 ? <><span></span></> :
                                                    <>
                                                    <div className='col-md-6 col-6'>
                                                        {this.state.update ?
                                                        <><button type='button' className='btn btn-danger' data-id={this.state.update._id} onClick={this.cancel}>Cancel Booking</button></>
                                                        : <button type='button' className='btn btn-danger' onClick={this.dispose}>Discard Booking</button>}
                                                    </div>
                                                    <div className='col-md-6 col-6 text-right'>
                                                        {this.state.update ?
                                                        <><button type='button' className='btn btn-success' onClick={this.savenow}>Save Now</button></>
                                                        :<button type='button' className='btn btn-success' onClick={this.booknow}>Book Now</button>}
                                                        
                                                    </div>
                                                    </>
                                                }
                                        </div>
                                        <hr />
                                    </div>
                                    : <>No Data Available</>
                                }
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default withParams(BookingNew);