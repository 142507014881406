import * as React from 'react';
import { Helmet } from "react-helmet";

class Settings extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            vars: []
        }
        
    }

    async componentDidMount(){
       await this.getVar();
    }

    getVar = async (vr = {}) => {
        let token = this.props.user.accessToken;
        let data = await this.props.app._getmongo(token, 'find', 'settings', vr,{_id: 1});
        if(data.ok){
            data = await data.json();
            this.setState({
                vars: data
            })
        }
    }

    addVar = async (e) => {
        e.preventDefault();
        const {sname, sparam} = e.target.elements;
        let token = this.props.user.accessToken;
        let data = [{_id: sname.value, sparam: sparam.value}];
        await this.props.app._setmongo(token, 'uniqueinsert', 'settings', data).then(async (res) => {
            if(res.ok){
                res = await res.json();
                
            }
           
            
            //this.fetchdata();
            //alert('New Slot Added / Updated');
        });
        await this.getVar();
        e.target.reset();
    }
    delVar = async (e) => {
        e.preventDefault();
        
        let vals = JSON.parse(e.target.getAttribute('values'));
        let conf = window.prompt('Type Name "' + vals._id + '" to Delete');
        if(conf === vals._id){
            let token = this.props.user.accessToken;
            await this.props.app._setmongo(token, 'deleteone', 'settings', {}, {_id: vals._id});
            this.getVar();
            window.alert('Name ' + vals._id + '  Deleted');
        }else{
            window.alert('Name did not matched')
        }
    }
    

    render() {
            return (
                <>
                    <Helmet>
                        <title>Settings</title>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <body id="page-top"></body>
                    </Helmet>
                    <div className='row'>
                        <div className="col-md-9">
                            <h1 className="h3 mb-2 text-gray-800">Settings</h1>
                        </div>

                    </div>
                    <div className="card shadow mb-4">
                        <ul className='list-group' id='settings'>
                            {
                                this.state.vars.map((x,i)=>{
                                    return <li className='list-group-item' key={i} >
                                        <form onSubmit={this.saveVar}>
                                            <div className='row'>
                                                
                                                <div className='col-sm-6 col-6 align-self-center'>
                                                <label htmlFor='sname'>Name</label> <sub>(JS Variable Friendly characters)</sub>
                                                <input id='sname' type={'text'} className='form-control' name='sname[]' placeholder='Setting Name' readOnly value={x._id} /><hr />
                                                <button onClick={this.delVar} values={JSON.stringify(x)} className='btn-sm btn-danger'>Delete</button>
                                                </div>
                                                <div className='col-sm-6 col-12'>
                                                    <label htmlFor={'sparam_' + i}>Parameters</label>
                                                    <textarea id={'sparam_' + i} className='form-control' name='sparam[]' placeholder='Setting Parameters' rows={'5'} readOnly value={typeof(x.sparam) === 'object' ? JSON.stringify(x.sparam) : x.sparam} ></textarea>
                                                </div>
                                                <hr />
                                            </div>
                                            </form>
                                    </li>
                                })
                            }
                            <li className='list-group-item'>
                                <form onSubmit={this.addVar} >
                                <div className='row'>
                                    
                                    <div className='col-sm-6 col-6 align-self-center'>
                                        <label htmlFor='sname'>Name</label> <sub>(JS Variable Friendly characters)</sub>
                                        <input id='sname' type={'text'} className='form-control' name='sname[]' placeholder='Setting Name' />
                                    </div>
                                    <div className='col-sm-6 col-12 align-self-center'>
                                        <label htmlFor='sparam'>Parameters</label>
                                        <textarea id='sparam' className='form-control' name='sparam[]' placeholder='Setting Parameters' rows={'2'} ></textarea>
                                    </div>
                                    <hr />
                                    <div className='col-12 text-right'>
                                        <button type='submit' className='btn btn-primary'>SAVE</button>
                                    </div>
                                </div>
                                </form>
                            </li>
                        </ul>
                    </div>
                </>
            );
    }
}


export default Settings