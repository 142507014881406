import * as React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import Helpers from '../../../Helpers';
const __h = new Helpers();

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class AssetNew extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedFile: [],
            filechange: [],
            uploading: false,
            saved: '',
            asset: {}
        };
        if(this.props.params.id){
            
            this.getAsset(this.props.params.id);
        }
       
    }
    
    getAsset = async(e) => {
        const id = typeof(e) === 'object' ? e.target : e;
        let token = this.props.user.accessToken;
        let asset = await this.props.app._getmongo(token,'findone','assets',{_id: {'objectid':id}});
        let files = [];

        if(asset.ok){
            asset = await asset.json();
            files['asset_pic'] = __h.parseImg(asset.asset_pic);
            files['inv_pic'] = __h.parseImg(asset.inv_pic);
            this.setState({
                asset: asset,
                selectedFile: files
            })
        }
        
        
    }
    
    onFileChange = async (event) => { 
        let filechange = this.state.filechange;
        //event.target.value = event.target.files[0].fil;
        let token = this.props.user.accessToken;
       
        let files = this.state.selectedFile;
        this.setState({ uploading: 'Uploading...'});
        filechange[event.target.getAttribute('id')] = 'Uploading... ' + event.target.files[0].name;
        //= event.target.files[0].name;
        
        const result = await this.props.app.fileUpload(token, event.target.files[0]);
        files[event.target.getAttribute('id')] = result;
        filechange[event.target.getAttribute('id')] = `<div className="row"><div className="col-6 align-self-center"><img style="max-width:100%;" src="${result.url}" className="max100" alt="${result.insertedId}" /></div><div className="col-6 align-self-center"><div class="text-success"><a href="${result.url}" target="_blank">${event.target.files[0].name}</a> Uploaded... <i class="fa fa-check"></i></div></div></div>`;
        this.setState({ selectedFile: files, filechange: filechange, uploading: false});
        
        //const uploadTask = uploadBytes(storageRef, file, metadata);

    }; 
    formFilter = (ele) => {
        return Object.entries(ele).filter(([k,v])=>{
            return isNaN(k);
        });
    }

    getFormValues = (ele) => {
        let xe = {};
        ele.forEach(x => {
            if(x[1].type === 'file' && this.state.selectedFile[x[0]] !== undefined){
                xe[x[0]] = JSON.stringify(this.state.selectedFile[x[0]]);
            }else{

                xe[x[0]] = x[1].value;
            }
            //
        });
        return xe
    }

   

    showPicker = (e) => {
        e.preventDefault();
        const el = e.target;
        if (el.getAttribute("type")==="date") {
            el.showPicker();
        }
    }

    uploadImage(imageList, addUpdateIndex){
        
    }

    saveAsset = async (e) => {
        e.preventDefault();
        let ele = e.target.elements;
        
        ele = this.formFilter(ele, true);
                
        const values = this.getFormValues(ele);
        this.setState({ uploading: 'Saving...'});
        values.inserted = new Date();
        
        let token = this.props.user.accessToken;
        let response;
        if(this.state.asset._id !== undefined){
            this.setState({ uploading: 'Updating...', saved: 'Updating Asset Info'});
            response = await this.props.app._setmongo(token,'updateone','assets', { $set: values }, {_id: {'objectid': this.state.asset._id}});

        }else{
            this.setState({ uploading: 'Saving...', saved: 'Saving Asset Info'});
            response = await this.props.app._setmongo(token,'insertone','assets', values);
        }
        
        if(response.ok){
            e.target.reset();
            return await response.json().then((res)=>{
                this.setState({ uploading: false, saved: res.insertedId, msg: 'Saved Asset Info'});
                return res;
            }).catch((err=>{
                
            }))
            
        }
        return false;
    }

    handleCheck = (e) => {
        
        let asset = this.state !== undefined ? this.state.asset : false;
        if(asset){
            asset.condition = e.target.value;
            this.setState({
             asset: asset
            })
        }
        
    }

    forceRelease = async (e) => {
        e.preventDefault();
        if(window.confirm('Are you sure to release this device forcefully. This will skip device usage log entry and booking status.')){
            let token = this.props.user.accessToken;
            let updateAsset = await this.props.app._setmongo(token, 'updateone','assets', { $set: {pid: "", status: 0} }, {_id: {'objectid': this.state.asset._id}});
           
            if(updateAsset.ok){
                token = this.props.user.accessToken;
                let asset = await this.props.app._getmongo(token,'findone','assets',{_id: {'objectid':this.state.asset._id}});
                
                if(asset.ok){
                    asset = await asset.json();
                    this.setState({
                        asset: asset
                    })
                }
            }
            
        }
    }

    render(){
        
        return (
            <>
            <Helmet>
                <title>Add New Bucket</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                
                <body id="page-top"></body>
            </Helmet>
            {
            this.state.uploading ? 
            <div className='preloader'>{this.state.uploading}</div> : 
            <></>
            }
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className='row'>
                        <div className="col-md-6">
                            <h1 className="h3 mb-2 text-gray-800">{this.state.asset._id !== undefined ? 'Changing in ' + this.state.asset.aname : 'Add New Bucket' }</h1>
                        </div>
                        <div className='col-md-6 text-right'>
                        {this.state.asset.pid === undefined || this.state.asset.pid === '' ? <></> : <button className='btn btn-dark' onClick={this.forceRelease}>Force Release Device</button> } | &nbsp;
                        <Link to='/assets' className='btn btn-primary'>Show All</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.saveAsset} method="post" encType="multipart/form-data">
                        <div className='row'>
                            <div className='col-md-12'>
                            {this.state.saved !== '' ? <div className='alert alert-success'><i className='fa fa-check'></i> {this.state.saved}</div> : ''}
                            </div>
                            <div className='col-md-8'>
                                <label>Name of the Asset</label>
                                <input type='text' id="aname" defaultValue={__h.defaultVal(this.state.asset.aname)} className="form-control" required />
                            </div>
                            <div className='col-md-4'>
                                <label>Bucket Label</label>
                                <input type='text' id="bname" defaultValue={__h.defaultVal(this.state.asset.bname)} placeholder='Unique Bucket Name or Number' className="form-control" required />
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Photo of Invoice</label>
                                    <input type='file' id="inv_pic" accept="image/*" onChange={this.onFileChange} className="form-control" />
                                    {
                                        this.state.filechange['inv_pic'] !== undefined ? 
                                        <div dangerouslySetInnerHTML={{__html: this.state.filechange['inv_pic']}} /> : <>{__h.defaultImg(this.state.asset.inv_pic !== undefined ? this.state.asset.inv_pic : '')}</>
                                    }
                                    
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Photo of Asset</label>
                                    <input type='file' id="asset_pic" accept="image/*" onChange={this.onFileChange} className="form-control"  />
                                    {
                                        
                                        this.state.filechange['asset_pic'] !== undefined ? 
                                        <div dangerouslySetInnerHTML={{__html: this.state.filechange['asset_pic']}} /> : <>{__h.defaultImg(this.state.asset.asset_pic !== undefined ? this.state.asset.asset_pic : '')}</>
                                    }
                                    
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Date of Purchase</label>
                                    <input type='date' id="dop" onFocus={this.showPicker} defaultValue={__h.defaultVal(this.state.asset.dop)} className="form-control" required />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Date of Entry</label>
                                    <input type='date' id="doe" onFocus={this.showPicker} defaultValue={__h.defaultVal(this.state.asset.doe)} className="form-control" required />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Invoice #</label>
                                    <input type='text' id="inv" className="form-control" defaultValue={__h.defaultVal(this.state.asset.inv)} required />
                                </div>
                                <div className='form-group'>
                                    <label>Serial / IMEI</label>
                                    <input type='text' id="aid" className="form-control" required defaultValue={__h.defaultVal(this.state.asset.aid)} />
                                </div>
                            </div>
                            
                            <div className='col-md-6 align-self-center'>
                                <div className='form-group'>
                                   <label>Accessories</label>
                                   <textarea name='accessories' className='form-control' required rows={4} defaultValue={__h.defaultVal(this.state.asset.accessories)}></textarea>
                                </div>
                            </div>
                            <div className='col-md-6 align-self-center'>
                                <div className='form-group'>
                                   <h4>Condition of Asset</h4>
                                   <div className='pl-1'>
                                    <div className="form-check form-check-inline">
                                            <input type='radio' name='condition' id='Good' checked={__h.defaultVal(this.state.asset.condition) === 'Good'} className='form-check-input condition-check' value='Good' onChange={this.handleCheck} /> <label className="form-check-label" required htmlFor='Good'>Good</label> 
                                    </div>
                                    <div className="form-check form-check-inline">
                                            <input type='radio' name='condition' id='Damaged' checked={__h.defaultVal(this.state.asset.condition) === 'Damaged'} className='form-check-input condition-check' value='Damaged' onChange={this.handleCheck} /> <label className="form-check-label" required htmlFor='Damaged'>Damaged</label> 
                                    </div>
                                    <div className="form-check form-check-inline">
                                            <input type='radio' name='condition' id='Lost' checked={__h.defaultVal(this.state.asset.condition) === 'Lost'} className='form-check-input condition-check' required value='Lost' onChange={this.handleCheck} /> <label className="form-check-label" htmlFor='Lost'>Lost</label> 
                                    </div>
                                    <div className="form-check form-check-inline">
                                            <input type='radio' name='condition' id='Decommisionned' checked={__h.defaultVal(this.state.asset.condition) === 'Decommisionned'} className='form-check-input condition-check' required value='Decommisionned' onChange={this.handleCheck} /> <label className="form-check-label" htmlFor='Decommisionned'>Decommisionned</label>  
                                    </div>
                                   </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                            {this.state.saved !== '' ? <div className='alert alert-success'><i className='fa fa-check'></i> {this.state.saved}</div> : ''}
                            </div>
                            <div className='col-md-12'>
                                <button type='submit' className='btn btn-theme'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </>
        )
    }
}

export default withParams(AssetNew);