import * as React from 'react';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

import '../../assets/vendor/datatables/dataTables.bootstrap4.min.css';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: false,
            user: false,
            load: 'Loading...'
        }
        
    }

    async componentDidMount(){
        let user;
        if (this.props.params.id) {
            user = await this.props.app.getFUser(this.props.user.accessToken, this.props.params.id);
        } else {
            user = await this.props.app.getFUser(this.props.user.accessToken, this.props.user.uid);
        }
        
        this.setState({
            user: user
        });
    }

    updateUser = async (e) => {
        e.preventDefault();
        const [email, dname, udisabled] = [
            e.target.elements['email'],
            e.target.elements['displayName'],
            e.target.elements['udisabled'],
        ];
        let data = {
            uid: this.state.user.uid,
            email: email.value,
            //phoneNumber: '+11234567890',
            //emailVerified: true,
           // password: 'newPassword',
            displayName: dname.value,
            //photoURL: 'http://www.example.com/12345678/photo.png',
            disabled: udisabled.checked,
          };
          let token = this.props.user.accessToken;
          let resp = await this.props.app.postReq(token, '/user', {post: data});
          if(resp.ok){
            
            alert('User Updated');
          }else{
            
          }
        //
    }

    render(){
        return (
            <>
                <Helmet>
                    <title>User Detail</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />


                    <body id="page-top"></body>
                </Helmet>

                {!this.state.user ? <></> :
                <>
                
                <h1 className="h3 mb-2 text-gray-800">User {this.state.user.uid}</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">User Detail</h6>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.updateUser}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="uid-addon1">UID</span>
                                    </div>
                                    <input type="text" disabled name="uid" class="form-control" placeholder="User ID" aria-label="uid" aria-describedby="uid-addon1" defaultValue={this.state.user.uid} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="email-addon1">Email</span>
                                    </div>
                                    <input type="text" name="email" disabled={this.state.user.emailVerified} class="form-control" placeholder="Email Address" aria-label="uid" aria-describedby="email-addon1" defaultValue={this.state.user.email} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="dname-addon1">Display Name</span>
                                    </div>
                                    <input type="text" name="displayName" id="displayName" class="form-control" placeholder="User ID" aria-label="displayName" aria-describedby="dname-addon1" defaultValue={this.state.user.displayName} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="mb-3">
                                    <h5>Login Type</h5>
                                    {Array.isArray(this.state.user.providerData) && this.state.user.providerData.length > 0 ? 
                                    <>
                                    {
                                        this.state.user.providerData.map((x)=>{
                                            return <span className='badge badge-dark'>{x.providerId}</span>
                                        })
                                    }
                                    </>
                                    :<></>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="usince-addon1">User Since</span>
                                    </div>
                                    <input type="text" disabled name="dname" class="form-control" placeholder="User ID" aria-label="uid" aria-describedby="usince-addon1" value={this.state.user.metadata !== undefined ? this.state.user.metadata.creationTime : ''} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="llogin-addon1">Last Login</span>
                                    </div>
                                    <input type="text" disabled name="llogin" class="form-control" placeholder="Login Type" aria-label="uid" aria-describedby="llogin-addon1" defaultValue={this.state.user.metadata !== undefined ? this.state.user.metadata.lastSignInTime : ''} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input type="checkbox" class="custom-control-input" id="udisabled" name='udisabled' defaultChecked={this.state.user.disabled} />
                                    <label class="custom-control-label" htmlFor="udisabled">Disabled</label><br />
                                    <small>This will block user from login / booking.</small>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input type="checkbox" class="custom-control-input" id="everified" readOnly name='everified' checked={this.state.user.emailVerified} />
                                    <label class="custom-control-label" htmlFor="everified">Email Verfied</label>
                                </div>
                            </div>
                            <div className='col-md-12 text-right'>
                                    <button className='btn btn-primary'>Save Changes</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
                </>
                }
            </>
        )
    }
}

export default withParams(User)