import * as React from 'react';
import QrReader from 'react-qr-scanner';
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { Link, useParams } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class BookingEdit extends React.Component {

    constructor(props) {
        super(props);
        this.bookingData = window.localStorage.getItem('bookingData');
        this.bookingData = this.bookingData === null || this.bookingData.length < 1 ? '[]' : this.bookingData;
        this.bookingData = JSON.parse(this.bookingData);
        
        this.state = {
            booking: this.bookingData,
            selectedDate: this.props.app.getDate(),
            selectedSlot: this.props.app.getDate(`h:00`, false, { minute: 60 }),
            person: {},
            update: false,
            settings: []
        }
        

    }

    async componentDidMount(){
        this.settings = await this.props.app.getSettings();
        this.setState({
            settings: this.settings.BookingStatus !== undefined ? this.settings.BookingStatus : this.settings
         });
         if (this.props.params.id) {
            this.getTicket(this.props.params.id);

        }
    }

    getStatus = (n = false) => {
        n = n === undefined ? -1 : parseInt(n);
        let status = this.state.settings.filter((x)=>{
            return x.status === n;
        });
        
        let text;
        if(status.length > 0){
            status = status[0];
            text = <span className={`badge badge-${status.type}`}>{status.msg}</span>
        }
        return text;
    }

    getTicket = async (id) => {
        let ticket = await this.props.app._getmongo(this.props.user.accessToken, 'findone', 'bookings', { _id: { 'ObjectId': id } });
        if (ticket.ok) {
            //window.localStorage.removeItem('bookingData');
            ticket = await ticket.json();
            this.setState({
                update: ticket
            });
            let dateslot = ticket.bookingslot.split(' ');
            document.getElementById('selecteddate').value = dateslot[0] !== undefined ? dateslot[0] : this.props.app.getDate();
            document.getElementById('slot').value = dateslot[1] !== undefined ? dateslot[1] : this.props.app.getDate(`h:00`, false, { minute: 60 });
            let people = await this.props.app._getmongo(this.props.user.accessToken, 'find', 'bookingPeople', { fid: ticket._id });
            let pdata = [];
            if (people.ok) {
                people = await people.json();
                people.forEach((x) => {
                    pdata.push({
                            pname: x.pname,
                            gender: x.gender,
                            dob: x.dob,
                            nationality: x.nationality,
                            dno: x.dno,
                            _id: x._id
                        });
                });
                this.bookingData = pdata;
                //window.localStorage.setItem('bookingData', JSON.stringify(this.bookingData));
                let tslot = ticket.bookingslot.split(' ');
                tslot = tslot[1] !== undefined ? tslot[1] : ticket.slot;
                this.setState({
                    booking: this.bookingData,
                    selectedDate: ticket.date,
                    selectedSlot: this.props.app.getDate(tslot, false, { minute: 60 }),
                    person: {}
                });
                
            }



        }
    }

    parseAadhar = async (e) => {
        let text = typeof (e) === 'object' && e.target !== undefined ? e.target.value : e;
        text = text.replace(new RegExp(/.+?(\?xml.*\?)>\s/gm, 'gm'), '').trim();
        let parser = new DOMParser();
        let xmlDoc;
        if (text.length > 12) {
            try {
                xmlDoc = parser.parseFromString(text, "text/xml");
                
                var attrs = xmlDoc.getElementsByTagName('PrintLetterBarcodeData');
                
                if (attrs.length > 0) {
                    attrs = Object.fromEntries(Array.from(attrs[0].attributes).map(item => [item.name, item.value]));
                    

                    attrs.dob = this.props.app.formatAnyDate(attrs.dob);
                    
                    const person = {
                        pname: attrs.name,
                        dob: attrs.dob,
                        gender: attrs.gender,
                        nationality: 'IN',
                        dno: attrs.uid
                    }
                    this.setState({
                        person: person
                    });

                }

                return attrs;
            } catch (ex) {
                this.setState({
                    person: {
                        pname: '',
                        dob: '',
                        gender: '',
                        nationality: '',
                        dno: e.target.value
                    }
                });
                
            }
        }


    }

    handleScan = async (data) => {
        if (data !== null) {

            if (this.state.id !== data.text) {
                this.setState({
                    qrcode: data.text
                });
                this.parseAadhar(data.text);

                //await this.getpersoninfo(data.text);
                var beepSound = new Audio('./assets/beep.mp3');
                beepSound.loop = false;
                beepSound.play();
            }

        }
    }



    handleError(err) {
        console.log(err)
    }


    formControl = (id, label, type, obj = {}, options = [], classname = 'form-control') => {
        let ctrl;
        switch (type) {
            case 'select':
                ctrl = () => {
                    return <div className='form-group'>
                        <label htmlFor={id}>{label}</label>
                        <select name={id} id={id} className={classname} {...obj} >
                            {options.map((x, i) => {
                                return <option key={i} index={i} value={x.value}>{x.text}</option>
                            })}
                        </select>
                    </div>
                }
                break;
            default:
                type = type === 'datetime' ? 'datetime-local' : type;
                ctrl = () => {
                    return <div className='form-group'>
                        <label htmlFor={id}>{label}</label>
                        <input type={type} name={id} id={id} className={classname} {...obj} />
                    </div>
                }
                break;
        }
        return ctrl();
    }

    showPicker = (e) => {
        e.preventDefault();
        const el = e.target;
        if (el.getAttribute("type") === "date" || el.getAttribute("type") === "time" || el.getAttribute("type") === "datetime-local") {
            el.showPicker();
        }
    }

    fixDate = (e) => {
        let val = e.target.value;
        
        this.setState({
            selectedDate: val
        });
    }

    fixSlot = (e) => {
        let val = e.target.value;
        let step = e.target.getAttribute('interval');
        val = val.split(':');
        
        if (val !== undefined && val[1] !== undefined && step !== undefined) {
            val[0] = parseInt(val[0]);
            val[1] = parseInt(val[1]);
            step = parseInt(step);
            let diff = val[1] === 0 || val[1] < step ? step : ((Math.floor(val[1] / step) * step) + step);
            val[1] = diff;
            if (diff === 60) {
                val[0]++;
                val[1] = '00';
            }
            val[0] = val[0] >= 24 ? 24 - val[0] : val[0];
            val[0] = val[0] < 10 ? '0' + val[0] : val[0];
            e.target.value = val.join(':');
            
            this.setState({
                selectedSlot: e.target.value
            });
        }
    }

    addPerson = async (e) => {
        e.preventDefault();
        const ele = e.target.elements;
        
        await this.parseAadhar(ele.dno.value);
        let pdata = {
            pname: ele.pname.value,
            gender: ele.gender.value,
            dob: ele.dob.value,
            nationality: ele.nationality.value,
            dno: ele.dno.value
        };
        

        // eslint-disable-next-line array-callback-return
        let found = this.bookingData.map((x, i) => {
            if (x.dno === ele.dno.value) {
                return i;
            }
        });
        let alert = "New Person Added!";
        found.forEach((fn) => {
            if (fn !== undefined) {
                this.bookingData.splice(fn, 1);
                alert = "Person's Details Updated!";
            }
        })

        this.bookingData.push(pdata);
        window.localStorage.setItem('bookingData', JSON.stringify(this.bookingData));
        this.setState({
            booking: this.bookingData,
            person: {}
        });
        ele.pname.value = ''; ele.gender.value = ''; ele.nationality.value = ''; ele.dno.value = '';

        //alert = 'Max allowed entries per slot has been exceeded! Please select another slot or do separate booking for other people in different slot';


        window.alert(alert);

    }

    dispose = (e) => {
        let confirm = window.confirm('Are you sure to discard this booking?');
        if (confirm) {
            window.localStorage.removeItem('bookingData');
            this.bookingData = window.localStorage.getItem('bookingData');
            this.bookingData = this.bookingData === null || this.bookingData.length < 1 ? '[]' : this.bookingData;
            this.bookingData = JSON.parse(this.bookingData);
            this.setState({
                booking: this.bookingData
            })
        }
    }

    cancel = async (e) => {
        const mobile = window.prompt('Enter registered booking mobile number or 12345!');
        if (!isNaN(mobile) && mobile.length > 4) {
            if (mobile === '12345' || mobile === this.state.update.mobile) {
                let token = this.props.user.accessToken;
                let resp = await this.props.app._setmongo(token, 'findoneupdate', 'bookings', { "$set": { "status": "-2" } }, { _id: { 'ObjectId': this.state.update._id } });
                if (resp.ok) {
                    resp = await resp.json();
                    await this.props.app._setmongo(token, '_updateone', 'AvailableSlots', { "$inc": { "Booked": (- this.state.booking.length) } }, { _id: this.state.selectedDate + ' ' + this.state.selectedSlot });
                    
                    window.location.reload();
                }
            } else {
                window.alert('Mobile Number did not matched');
            }
        } else {
            window.alert('Invalid Mobile Number');
        }
    }


    removePerson = async (e) => {
        let confirm = window.confirm('Are you sure you want to remove the person: ');
        if (confirm) {
            const index = e.target.getAttribute('data-index');
            let booking = this.state.booking;
            booking[index]['del'] = 1;
            this.setState({
                booking: booking
            })
            
        }
    }
    cancelRemove = async (e) => {
        const index = e.target.getAttribute('data-index');
        let booking = this.state.booking;
        delete (booking[index]['del']);
        this.setState({
            booking: booking
        })
        
    }

    isISD = (n) => {
        
        var reg = new RegExp(/^[+][0-9]+[-]+[0-9]{4,14}$/g);
        return reg.test(n);
    }
    toISD = (n, d = '+91-') => {
        
        if(!this.isISD(n)){
            n = d + n;
            
            if(!this.isISD(n)){
                return;
            }        
        }
        
        return n
    }

    savenow = async (e) => {
        //
        e.preventDefault();
        const mobile  = window.prompt('Enter mobile number, ISD Number must be +(ISD_CODE)-(Number) format. For E.G: US number +1-987626222');
        if(this.isISD(mobile) || (mobile.length > 4 && Number.isInteger(parseInt(mobile)))){
            //const country = '91';
            let pfm = this.toISD(mobile);
            console.log(pfm)
            
            if(pfm === undefined){
                alert('ISD Number must be +(ISD_CODE)-(Number) format. For E.G: US number +1-987626222');
            }else{
                this.bookingData = this.bookingData.map((x)=>{
                    x['mobile'] = this.toISD(mobile).replace('+','');
                    
                    return x;
                });
                
                
                let token = this.props.user.accessToken;
                let booking = this.state.booking;
                //booking.id = this.state.update._id;
                let bookingData = {
                    slot: this.state.selectedSlot,
                    date: this.state.selectedDate,
                    people: this.state.booking,
                    mobile: pfm,
                    bkid: this.state.update._id
                };
                let resp = await this.props.app.processBooking(token, bookingData, this.state.update._id);
                //
                
                this.setState({
                    booking: booking
                })
                
                let result = false;
                if(resp.ok){
                    result = await resp.json();
                }
                if(result){
                    if(result.res === 'success'){

                        window.localStorage.removeItem('bookingData');
                    
                        alert('Mobile Verified & Updated!');
                        window.location.href = window.location.protocol + '//' + window.location.host + '/booking/' + result.id;
                    }else{
                        console.log(result);
                        alert(result.msg);
                    }
                }else{
                    console.log(resp.status);
                    if(resp !== undefined && resp.status === 403){
                        alert('Session Expired! Click ok to refresh the page!');
                        window.location.reload();
                    }else{
                        alert('Booking Failed! Contact Administrator!');
                    }
                }
                //
                //this.setState({
                //    booked: true
                //});
                
                ///window.location.href = window.location.protocol + '//' + window.location.host + '/bookings/';
            }
            
            
        }else{
            alert('Invalid Mobile Number')
        }
    }

    booknow = async (e) => {
        e.preventDefault();
        const mobile = window.prompt('Enter 10 Digit Mobile Number from which SMS sent!');
        if (!isNaN(mobile) && mobile.length > 4) {
            const country = '91';
            let pfm = country + mobile;
            await this.props.app.getInbox();
            const inbox = this.props.app.smsinbox;
            if(Array.isArray(inbox.messages)){
                inbox.messages.push({number: pfm});
            }
            const found = inbox.messages !== undefined && Array.isArray(inbox.messages) ? inbox.messages.map((x) => {
                const numb = x['number'].toString();
                if (numb === pfm) {
                    return numb;
                } else {
                    return false;
                }
            }) : ['9112345', '9100000'];
            if (found.includes(pfm)) {
                this.bookingData = this.bookingData.map((x) => {
                    x['mobile'] = country + '-' + mobile;

                    return x;
                });

                this.bookingData['slot'] = this.state.selectedSlot;
                this.bookingData['date'] = this.state.selectedDate;
                let token = this.props.user.accessToken;
                let resp = await this.props.app.processBooking(token, this.bookingData);
                resp = resp.ok ? await resp.json() : false;
                
                if (resp.id !== undefined) {

                    window.localStorage.removeItem('bookingData');

                    alert('Mobile Verified & Booking Done!');
                    window.location.href = window.location.protocol + '//' + window.location.host + '/booking/' + resp.id;
                } else {
                    alert('Booking Failed! Contact Administrator!');
                }
                //
                //this.setState({
                //    booked: true
                //});

                ///window.location.href = window.location.protocol + '//' + window.location.host + '/bookings/';
            } else {
                alert('SMS not recieved! Please wait for a few seconds and try again!');
            }


        } else {
            alert('Invalid Mobile Number')
        }
        //
    }


    render() {
        const previewStyle = {
            height: '100%',
            width: '100%',
        }
        return (<>
            <Helmet>
                <title>Edit Booking</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <body id="page-top"></body>
            </Helmet>
            <div className='qrscanner d-none do-not-print'>
                <div className="QRtv">
                    <button className='btn btn-secondary btn-hide' onClick={this.toggleTV}>{this.state.toggleTVtext}</button>
                    <div className={this.state.toggleTVtext === 'Show Screen' ? 'tv d-none' : 'tv'}>

                        <QrReader
                            delay={this.state.delay}
                            style={previewStyle}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            constraints={{
                                video: {
                                    facingMode: {
                                        exact: isMobile ? "environment" : "user"
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className='row'>
                        <div className="col-md-9">
                            <h1 className="h3 mb-2 text-gray-800">{!this.state.update ? 'Add New Booking' : `Updating Ticket# ${this.state.update._id}`}</h1>
                        </div>
                        <div className='col-md-3 text-right'>
                            <Link to={'/booking/' + this.props.params.id} className='btn btn-primary'><i className='fa fa-eye'></i> View</Link> | <Link to={'/bookings'} className='btn btn-primary'><i className='fa fa-list'></i> View All</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.addPerson}>
                        <div className='row'>
                            <div className='col-md-3'>
                                {this.formControl('selecteddate', 'Booking Date', 'date', {
                                    placeholder: 'Booking Date',
                                    required: 'required',
                                    onChange: this.fixDate,
                                    onClick: this.showPicker,

                                    min: !this.state.update ? this.props.app.getDate() : '',
                                    defaultValue: this.state.update !== false ? this.state.update.date : this.props.app.getDate(),
                                    disabled: parseInt(this.state.update.status) > 0 || parseInt(this.state.update.status) < 0
                                })}
                            </div>
                            <div className='col-md-3'>
                                {this.formControl('slot', 'Booking Slot', 'time', {
                                    placeholder: 'Booking Slot',
                                    required: 'required',
                                    onChange: this.fixSlot,
                                    onClick: this.showPicker,
                                    interval: 15,
                                    defaultValue: !this.state.update ? this.props.app.getDate(`h:00`, false, { minute: 60 }) : this.state.update.slot,
                                    disabled: parseInt(this.state.update.status) > 1 || parseInt(this.state.update.status) < 0
                                })}
                            </div>
                            <div className='col-md-6'>
                                {this.getStatus(this.state.update.status)}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                {this.formControl('pname', 'Person Name', 'text', {
                                    'placeholder': 'Person Name',
                                    required: 'required',
                                    value: this.state.person.pname,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>
                            <div className='col-md-2'>
                                {this.formControl('dob', 'Date of Birth', 'date', {
                                    'placeholder': 'Date of Birth',
                                    required: 'required',
                                    onFocus: this.showPicker,
                                    max: this.props.app.getDate('Y-M-D', false, { year: -5 }),
                                    value: this.state.person.dob,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>
                            <div className='col-md-1'>
                                {this.formControl('gender', 'Gender', 'select', {
                                    required: 'required',
                                    value: this.state.person.gender,
                                    disabled: this.state.update.status === '-2' ? true : false
                                }, [
                                    { text: 'Male', value: 'M' },
                                    { text: 'Female', value: 'F' },
                                    { text: 'Other', value: 'O' },
                                ])}
                            </div>
                            <div className='col-md-2'>
                                {this.formControl('nationality', 'Nationality', 'select', { required: 'required', value: this.state.person.nationality, disabled: this.state.update.status === '-2' ? true : false }, [
                                    { text: 'Indian', value: 'IN' },
                                    { text: 'Other', value: 'OTHER' }
                                ])}
                            </div>
                            <div className='col-md-3'>
                                {this.formControl('dno', 'Document Number', 'text', {
                                    placeholder: 'Aadhaar Number If Indian Otherwise Passport. Check passport expiry',
                                    required: 'required',
                                    onChange: this.parseAadhar,
                                    onBlur: this.parseAadhar,
                                    value: this.state.person.dno,
                                    disabled: this.state.update.status === '-2' ? true : false
                                })}
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-right'>
                                <button type='submit' className='btn btn-primary' disabled={this.state.update.status === '-2' ? true : false}>Add Person</button>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-md-12'>

                            {
                                Array.isArray(this.state.booking) && this.state.booking.length > 0 ?
                                    <div className='table-responsive'>
                                        <hr />
                                        <div className='row'>

                                            {
                                                this.state.update.status === '-2' || this.state.update.status > 1 ? <><span></span></> :
                                                    <>
                                                        <div className='col-md-6 col-6'>
                                                            {this.state.update ?
                                                                <><button type='button' className='btn btn-danger' data-id={this.state.update._id} onClick={this.cancel}>Cancel Booking</button></>
                                                                : <button type='button' className='btn btn-danger' onClick={this.dispose}>Discard Booking</button>}
                                                        </div>
                                                        <div className='col-md-6 col-6 text-right'>
                                                            {this.state.update ?
                                                                <><button type='button' className='btn btn-success' onClick={this.savenow}>Save Now</button></>
                                                                : <button type='button' className='btn btn-success' onClick={this.booknow}>Book Now</button>}

                                                        </div>
                                                    </>
                                            }

                                        </div>
                                        <hr />
                                        <table className='table table-hover'>
                                            <thead>
                                                <tr>
                                                    <td>Person</td>
                                                    <td>Dob</td>
                                                    <td>Gender</td>
                                                    <td>Nationality</td>
                                                    <td>Document ID</td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.booking.map((x, i) => {
                                                        
                                                        return (<tr key={i} style={x.del !== undefined ? { textDecoration: 'line-through' } : {}}>
                                                            <td>{x.pname}</td>
                                                            <td>{x.dob}</td>
                                                            <td>{x.gender}</td>
                                                            <td>{x.nationality}</td>
                                                            <td>{x.dno}</td>
                                                            <td className='text-right'>
                                                                {
                                                                    this.state.update.status === '-2' || this.state.update.status > 1 ? <><span></span></> :
                                                                        <>
                                                                            {
                                                                                x.del ?
                                                                                    <button data-index={i} data-id={(x._id !== undefined ? x._id : i)} id={'p_' + (x._id !== undefined ? x._id : i)} onClick={this.cancelRemove} className="btn btn-danger" title='Cancel Delete'>Cancel</button>
                                                                                    : <button data-index={i} data-id={(x._id !== undefined ? x._id : i)} id={'p_' + (x._id !== undefined ? x._id : i)} onClick={this.removePerson} className="btn btn-danger" title='Delete'>Delete</button>
                                                                            }
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                        <hr />
                                        <div className='row'>
                                            {
                                                this.state.update.status === '-2' || this.state.update.status > 1 ? <><span></span></> :
                                                    <>
                                                        <div className='col-md-6 col-6'>
                                                            {this.state.update ?
                                                                <><button type='button' className='btn btn-danger' data-id={this.state.update._id} onClick={this.cancel}>Cancel Booking</button></>
                                                                : <button type='button' className='btn btn-danger' onClick={this.dispose}>Discard Booking</button>}
                                                        </div>
                                                        <div className='col-md-6 col-6 text-right'>
                                                            {this.state.update ?
                                                                <><button type='button' className='btn btn-success' onClick={this.savenow}>Save Now</button></>
                                                                : <button type='button' className='btn btn-success' onClick={this.booknow}>Book Now</button>}

                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default withParams(BookingEdit);