import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Dashboard extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            bookings: [],
            assets: [],
            settings: []
        }
    }

    async componentDidMount(){
        this.settings = await this.props.app.getSettings();
        this.setState({
           settings: this.settings.BookingStatus !== undefined ? this.settings.BookingStatus : this.settings
        });
        await this.getAssets([
            {
              '$addFields': {
                'pid': {
                  '$convert': {
                    'input': '$pid', 
                    'to': 'objectId', 
                    'onError': '', 
                    'onNull': ''
                  }
                }
              }
            }, {
              '$lookup': {
                'from': 'brindavan_bookingPeople', 
                'localField': 'pid', 
                'foreignField': '_id', 
                'as': 'people'
              }
            }, {
                $sort: {
                    bname: 1
                }
            }
          ]);
        await this.getBookings(new Date(this.date2slot()),'today',{
            bookingslot: 1
        });
        console.log(this.state)
    }

    getStatus = (n) => {
        n = n === undefined ? -1 : parseInt(n);
        let status = this.state.settings.filter((x)=>{
            return x.status === n;
        });
        
        let text;
        if(status.length > 0){
            status = status[0];
            text = <div><span className={`badge badge-${status.type}`}>{status.msg}</span></div>
        }
        return text;
    }
    

    getAssets = async(filter = {}, sort = {status: -1, inserted: -1}) => {
        let token = this.props.user.accessToken;
        const response = await this.props.app._getmongo(token,'aggregate','assets',filter,sort);

        if(response.ok){
            this.assets = await response.json();
            this.assets.summary = {
                total: this.assets.length,
                allocated: this.assets.filter((x)=>{
                    return typeof(x.pid) !== 'undefined' && x.pid.length > 0;
                }).length,
                available: this.assets.filter((x)=>{
                    return typeof(x.pid) === 'undefined' || x.pid.length < 1;
                }).length,
            }
            
            this.setState({
                assets: this.assets
            })
        }
    }


    date2slot = (date = new Date(), slot = 15) => {
        let d = {
            d: this.props.app.leadzero(date.getDate()),
            m: this.props.app.leadzero(date.getMonth() + 1),
            y: date.getFullYear(),
            H: this.props.app.leadzero(date.getHours()),
            M: this.props.app.leadzero(date.getMinutes())
        }
        d.M = d.M !== '00' ? this.props.app.leadzero(Math.floor(d.M / slot) * slot) : '00';
        return `${d.y}-${d.m}-${d.d} ${d.H}:${d.M}`;
    }

    getBookings = async(dt = new Date(), type='recent', order = {
        bookingDate: -1,
        bookingslot: -1
    }, filter = false) => {
        let token = this.props.user.accessToken;
        let bookings = this.state.bookings;
        filter = !filter ? {
            "$expr": {
                "$gte": [
                    {"$dateFromString": { "dateString": "$bookingslot" }},
                    { "$dateFromString": { "dateString": dt }}
                    ] 
            }
         } : filter;
        let bk = await this.props.app._getmongo(token,'find','bookings',filter,order);
        if(bk.ok){
            bk = await bk.json();
            bookings[type] = bk;
            
            this.setState({
                bookings: bookings
            });
            
        }
    }

    render(){
        return (
            <>
            <Helmet>
                <title>Dashboard</title>
                <meta name='viewport' content='width=device-width,initial-scale=1,user-scalable=no, maximum-scale=1.0, shrink-to-fit=no' />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                <body id="page-top"></body>
                

            </Helmet>
            <h1 className="h3 mb-2 text-gray-800 do-not-print">Dashboard</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Device &amp; People Monitor</h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            
                            {
                                Array.isArray(this.state.assets) ?
                                <ul className='devicelist'>
                                    {
                                        this.state.assets.map((x)=>
                                        {
                                            return <li className={x.people !== undefined && x.people.length > 0 ? 'bg-danger' : ''}>
                                                
                                                {
                                                    x.people.length > 0 ? 
                                                    <div >
                                                        {x.people[0].remarks !== undefined ?
                                                        <div className='text-white'>{x.people[0].remarks}</div>:<></>
                                                        }
                                                        <span style={{borderRadius:'50%',padding:'10px'}} className='badge badge-dark display-4'>{x.bname}</span><br />
                                                        <span className='pname'><Link className='text-white' smooth to={`/booking/${x.people[0].fid}?pid=${x.people[0]._id}`} hash={x.people[0]._id}>{x.people[0].pname}</Link></span>
                                                        
                                                        
                                                    </div> : <><i className='fa fa-headphones'></i> <span style={{borderRadius:'50%',padding:'10px'}} className='badge badge-dark display-4'>{x.bname}</span> <i className='fa fa-mobile'></i></>
                                                }
                                            </li>
                                        })
                                    }
                                </ul>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Next Bookings</h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            {
                                !this.state.bookings ? <></> :
                                <>
                                {Array.isArray(this.state.bookings['today']) && this.state.bookings['today'].length > 0 ? 
                                <div className='table-responsive'>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item badge badge-info'>Total Bookings: {this.state.bookings['today'].length}</li>
                                        <li className='list-inline-item badge badge-dark'>
                                        Not Arrived: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === -1}).length}
                                        </li>
                                        <li className='list-inline-item badge badge-warning'>Getting In: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 1}).length}</li>
                                        <li className='list-inline-item badge badge-danger'>Checked-in: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 2}).length}</li>
                                        <li className='list-inline-item badge badge-info'>Returned: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 3}).length}</li>
                                        <li className='list-inline-item  badge badge-success'>Checked Out: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 4}).length}</li>
                                    </ul>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>BookingID</th>
                                                <th className='text-center'>Booking Date</th>
                                                <th className='text-center'>Booking Slot</th>
                                                <th className='text-center'>Mobile</th>
                                                <th className='text-center'>UserID</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.bookings['today'].map((x)=>{
                                                    return (<tr key={x._id}>
                                                        <td className='text-center'><Link to={'/booking/' + x._id}>{x._id}</Link></td>
                                                        <td className='text-center'>{x.bookingDate}</td>
                                                        <td className='text-center'>{x.bookingslot}</td>
                                                        <td className='text-center'>{x.mobile !== null && x.mobile.length > 8 ? <a href={'https://wa.me/'+x.mobile.replace('-','')} target='_blank' title='Whatsapp' rel="noreferrer">{x.mobile.replace('-','')}</a> : <>SSSDivyaSmrti</>}</td>
                                                        <td className='text-center'><Link to={'/user/' + x.uid}>{x.uid === null ? 'NA' : x.uid}</Link></td>
                                                        <td>{this.getStatus(x.status)}</td>
                                                    </tr>)
                                                })
                                            }
                                            
                                        
                                        </tbody>
                                    </table>
                                </div>
                                :<></>}
                                </>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Recent Bookings on </h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <>
                                {Array.isArray(this.state.bookings['recent']) && this.state.bookings['recent'].length > 0 ? 
                                <div className='table-responsive'>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item badge badge-info'>Total Bookings: {this.state.bookings['recent'].length}</li>
                                        <li className='list-inline-item badge badge-dark'>
                                        Not Arrived: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === -1}).length}
                                        </li>
                                        <li className='list-inline-item badge badge-warning'>Getting In: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 1}).length}</li>
                                        <li className='list-inline-item badge badge-danger'>Checked-in: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 2}).length}</li>
                                        <li className='list-inline-item badge badge-info'>Returned: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 3}).length}</li>
                                        <li className='list-inline-item  badge badge-success'>Checked Out: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 4}).length}</li>
                                    </ul> 
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>BookingID</th>
                                                <th className='text-center'>Booking Date</th>
                                                <th className='text-center'>Booking Slot</th>
                                                <th className='text-center'>Mobile</th>
                                                <th className='text-center'>UserID</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.bookings['recent'].map((x)=>{
                                                    return (<tr key={x._id}>
                                                        <td className='text-center'><Link to={'/booking/' + x._id}>{x._id}</Link></td>
                                                        <td className='text-center'>{x.bookingDate}</td>
                                                        <td className='text-center'>{x.bookingslot}</td>
                                                        <td className='text-center'>{x.mobile !== null && x.mobile.length > 8 ? <a href={'https://wa.me/'+x.mobile.replace('-','')} target='_blank' title='Whatsapp' rel="noreferrer">{x.mobile.replace('-','')}</a> : <>SSSDivyaSmrti</>}</td>
                                                        <td className='text-center'><Link to={'/user/' + x.uid}>{x.uid === null ? 'NA' : x.uid}</Link></td>
                                                        <td>{this.getStatus(x.status)}</td>
                                                    </tr>)
                                                })
                                            }
                                            
                                        
                                        </tbody>
                                    </table>
                                </div>
                                :<></>}
                                </>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default withParams(Dashboard);