import * as React from 'react';
import { Helmet } from "react-helmet";
import { Link, useParams } from 'react-router-dom';
import Helpers from '../../Helpers';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const __h = new Helpers();

class Bookings extends React.Component {

    constructor(props) {
        super(props);
        this.settings = [];
        this.state = {
            bookings: [],
            settings: this.settings
        };
        
    }

    async componentDidMount(){
        this.settings = await this.props.app.getSettings();
        this.setState({
           settings: this.settings.BookingStatus !== undefined ? this.settings.BookingStatus : this.settings
        });
        if (this.props.params.id) {
            
            await this.getBookings(this.props.params.id);

        } else {

           await this.getBookings({});
        }
    }

    getTicketStatus = (n) => {
        n = n === undefined ? -1 : parseInt(n);
        let status = this.state.settings.filter((x)=>{
            return x.status === n;
        });
        
        let text;
        if(status.length > 0){
            status = status[0];
            text = <div><span className={`badge badge-${status.type}`}>{status.msg}</span></div>
        }
        return text;
    }

    getBookings = async (filter) => {
        
        if (typeof (filter) === 'string') {
            filter = {
                "$or": [{
                    "mobile": filter
                }, {
                    "date": filter
                }, {
                    "bookingslot": filter
                }, {
                    "bookingDate": filter
                }]
            }
        } else if (typeof (filter) === 'object' && filter.target !== undefined) {
            filter = document.getElementById('filterBooking').value === '' ? {} : {
                "$or": [
                {
                    "_id": {"Objectid": document.getElementById('filterBooking').value}
                }, {
                    "mobile": { $regex: `${document.getElementById('filterBooking').value}` } 
                }, {
                    "date": { $regex: `${document.getElementById('filterBooking').value}` } 
                }, {
                    "bookingslot": { $regex: `${document.getElementById('filterBooking').value}` }
                }, {
                    "bookingDate": { $regex: `${document.getElementById('filterBooking').value}` }
                }]
            }
        }
        
        let token = this.props.user.accessToken;
        let data = await this.props.app._getmongo(token, 'sortfind', 'bookings', filter, { bookingDate: -1 });

        if (data.ok) {
            data = await data.json();
            
            this.setState({
                bookings: data
            });
        }

    }

    changeID = (e) => {
        this.setState({
            id: e.target.value
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bookings</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />


                    <body id="page-top"></body>
                </Helmet>
                <div className='row'>
                    <div className="col-md-9">
                        <h1 className="h3 mb-2 text-gray-800">Bookings</h1>
                    </div>
                    <div className='col-md-3 text-right'>
                        <Link className='btn btn-primary' to={'/booking/new'}>Add New</Link>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Booking List</h6>
                    </div>
                    <div className="card-body">
                        <div className='searchbox'>
                            <div className='input-group mb-3'>
                                <input type='text' onChange={this.changeID} id="filterBooking" className='form-control' onBlur={this.getBookings} placeholder='Find Booking' />
                                <div className="input-group-append">
                                    <button onClick={this.getBookings} data-id={this.state.id} className="input-group-text bg-primary text-white">Find</button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            {Array.isArray(this.state.bookings) && this.state.bookings.length > 0 ?
                                (<>
                                    <table className='table table-stripe table-hover'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className='text-center'>BookingID</th>
                                                <th className='text-center'>Mobile</th>
                                                <th className='text-center'>Booking Date</th>
                                                <th className='text-center'>Booking Slot</th>
                                                <th className='text-center'>Status</th>
                                                <th className='text-center'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.bookings.map((x, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td className='text-center'><div className='alert alert-dark'>{x._id}</div>
                                                            {
                                                                x.uid === null || x.uid === undefined ? <></> :
                                                                <>
                                                                <strong>UserID</strong><br />
                                                                <Link to={'/user/'+x.uid}>{x.uid}</Link>
                                                                </>
                                                            }
                                                            </td>
                                                            <td className='text-center'>{x.mobile}<br /><a href={'tel:+' + __h.numfilter(x.mobile)} target='_blank' rel="noreferrer" title='Call' >Call</a> | <a href={'https://wa.me/' + __h.numfilter(x.mobile)} target='_blank' title='Whatsapp' rel="noreferrer">Whatsapp</a></td>
                                                            <td className='text-center'>{x.bookingDate}</td>
                                                            <td className='text-center'>{x.date} {x.slot}</td>
                                                            <td className='text-center'>{this.getTicketStatus(x.status)}</td>
                                                            <td width={'15%'} className='text-center'><Link to={'/booking/edit/' + x._id} className='btn btn-dark btn-sm' ><i className='fa fa-edit'></i></Link> <Link to={'/booking/' + x._id} className='btn btn-theme btn-sm' ><i className='fa fa-eye'></i></Link></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </>)
                                : (<></>)}
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default withParams(Bookings)