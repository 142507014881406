import * as React from 'react';
import QrReader from 'react-qr-scanner'
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { useParams } from 'react-router';
import Helpers from '../../Helpers';
import { Link } from 'react-router-dom';

const __h = new Helpers();

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Booking extends React.Component{

    constructor(props){
        super(props)
        this.state = {
          delay: 100,
          id: '',
          qrcode: '',
          data: false,
          dlist: [],
          finding: false,
          toggleTVtext: 'Hide Screen',
          showIframe: false,
          showModal: false,
          found: '',
          cdevice: '',
          spid: (this.props.app.query !== undefined && this.props.app.query.pid !== undefined)
        }
        this.devices = navigator.mediaDevices;
        if(this.props.params.id){

            this.getTicket(this.props.params.id);
            
        }
        
       // this.handleScan = this.handleScan.bind(this)
    }

    toggleTV = (e) => {
        this.setState({
            toggleTVtext: this.state.toggleTVtext === 'Hide Screen' ? 'Show Screen' : 'Hide Screen'
        })
    }

    getTicketStatus = (n) => {
        n = n === undefined ? -1 : parseInt(n);
        let text;
        switch(n){
            case 0:
                text = <div>
                    <span className="text-danger">Validation Complete!</span><br /><small>Allowed to get devices</small>
                </div>;
            break;
            case 1:
                text = <div>
                    <span className='text-success'><i className='fa fa-check'></i></span><br /><small>All people have been verified in this booking.</small>
                </div>
            break;
            case 2:
                text = <div>
                    <span className='btn btn-warning'>CheckedIn</span>
                </div>
            break;
            case 3:
                text = <div>
                    <span className='btn btn-success'>Returned Docket</span>
                </div>
            break;
            case 4:
                text = <div>
                    <span className='btn btn-success'>Checked Out</span>
                </div>
            break;
            case -1:
                text = <div>
                    <span className="text-danger">Invalid Document!</span><br /><small>After verification of all people in this booking check-in will be allowed.</small>
                </div>;
            break;
            case -2:
                text = <div>
                    <span className="text-danger">Cancelled Booking</span>
                </div>;
            break;
            default:
                text = <div>
                    <span className="text-danger">Ahh, not allowed to check-in yet!</span><br /><small>After verification of all people in this booking check-in will be allowed.</small>
                </div>;
            break;
        }
        return text;
    }

    isValid = (d, doc = true) => {
        d = d === undefined ? 0 : parseInt(d);
        return d > 0;
    }

    createQR = (data, color = false) =>{
        color = color ? color : '000000';
        data = encodeURI(data);
        return `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${data}&color=${color}`;
    }

    getDeviceStatus = (d, index) => {
        d = d === undefined ? 0 : parseInt(d);
        let template;
        switch(d){
            case -1:
                template = (
                    <div>
                        <span className="text-danger">Invalid Document</span>
                    </div>
                );
            break;
            case 0:
                template = (
                    <div>
                        <span className="text-danger">Getting In</span><br />
                    </div>
                );
            break;
            case 1:
                template = (
                    <div>
                        <span className="text-default">Not CheckedIn</span><br />
                        <button className='btn btn-sm btn-warning' index={index} func='new' onClick={this.showModalE}>Give Device</button>
                        
                    </div>
                );
            break;
            case 2:
                template = (
                    <div>
                        <span className="text-success do-not-print">Checked-In:</span><br />
                        <button id={"d_" + this.state.data.people[index].device} className='btn btn-sm btn-danger do-not-print' index={index} func='getback' onClick={this.showModalE}>Get back / Change Docket</button><br className="do-not-print" />
                        <strong>{this.state.data.people[index].remarks}</strong> (are there in the bucket)
                        
                        <br className='do-not-print' />
                        <img alt='' style={{maxWidth:'99px'}} src={this.createQR(this.state.data.people[index].device)} />
                        <br />device# {this.state.data.people[index].device}
                        
                    </div>
                );
            break;
            case 3:
                template = (
                    <div>
                        <strong>{this.state.data.people[index].remarks}</strong> (were there in the bucket)
                        <span className="text-success">Returned Device</span><br />
                        Sairam! Thank you for visiting.
                    </div>
                );
            break;
            case 4:
                template = (
                    <div>
                        <span className="text-success">Checked Out</span><br />
                        
                    </div>
                );
            break;
            case 5:
                template = (
                    <div>
                        <span className="text-success">Checked Out</span><br />
                    </div>
                );
            break;
            default:
                template = (
                <div>
                    <button className='btn btn-sm btn-danger'>Not Available</button>
                </div>
                );
            break;
        }
        return template;
    }

    

    getTicket = async(e) => {
        
        this.setState({
           // data: false,
            finding: true
        })
        const id = typeof(e) === 'object' ? e.target.getAttribute('data-id') : e;
        
        const ticket = await this.props.app._getmongo(this.props.user.accessToken, 'findone','bookings', {_id: {'ObjectId': id}});
        
       // this.props.app.authUser();
        if(ticket.ok){
            let data = {};
            data['ticket'] = await ticket.json();
            if(data['ticket'] !== null && Object.keys(data['ticket']).length > 0){
                    const user = await this.props.app.getReq(this.props.user.accessToken,'/user?id=' + data['ticket'].uid);
                    data['user'] = user;
                
                    const people = await this.props.app._getmongo(this.props.user.accessToken,'find','bookingPeople',{fid: data['ticket']._id});
             //   this.props.app.authUser();
                if(people.ok){
                    data['people'] = await people.json();
                }
                this.setState({
                    data: data,
                    finding: false,
                    found: ''
                });
            }else{
                let ele = document.querySelector('#d_' + id);
                if(ele instanceof HTMLElement){
                    ele.click();
                }
                this.setState({
                    //data: [],
                    finding: false,
                    found: ''
                })
            }
            
            
        }
       // 
        
        //let people = await this.props.app._getmongo(token, 'find','bookings', {_id: {'ObjectId': id}});
        //
        
    }

    handleScan = (data) => {
        if(data !== null){
            
            if(this.state.id !== data.text)
            {
                this.setState({
                    id: data.text
                });
                this.getTicket(data.text);
                var beepSound = new Audio('./assets/beep.mp3');
                beepSound.loop = false;
                beepSound.play();
            }
            
        }
    }

    showFrame = (e) => {
        let adetail = e.target.getAttribute('adetail');
        const url = e.target.getAttribute('url');
        adetail = JSON.parse(adetail);
        
        if(url !== '#'){
          //  navigator.clipboard.writeText(adetail.ano);
            //alert('Adhaar Number Copied.');
        }
        
        //
       // 
        this.setState({
            showIframe: url,
            aadhar: adetail
        });
    }

    closeFrame = (e) => {
        this.setState({
            showIframe: false,
            aadhar: ''
        })
    }

    getDeviceInfo = async () => {
        let resp = await this.props.app._getmongo(this.props.user.accessToken,'find','assets', {});
        
        let arr = [];
        if(resp.ok){
            let data = await resp.json();
            
            for(let i = 0; i < data.length; i++){
                arr[data[i]._id] = data[i];
            }
            
        }
        return arr;
    }

    showModalE = async(e) => {
        this.setState({
            aadhar: [],
           id: '' 
        })
        const index = e.target.getAttribute('index');
        const func = e.target.getAttribute('func'); // new / getback
        let person = this.state.data.people[index];
        person['index'] = index;
        person['func'] = func;
        let resp = await this.props.app._getmongo(this.props.user.accessToken,'find','assets',{ $or: [ { pid: null }, { pid: person._id }, { pid: ""} ] });
        if(resp.ok){
            let devices = await resp.json();
            devices = devices.sort((a,b) => (a.bname > b.bname) ? 1 : ((b.bname > a.bname) ? -1 : 0));
            console.log(devices);
            let getinfo = devices.filter((x)=>{
                return x._id === person.device;
            });
            
            let xdevs = devices.map((x)=>{
                return {value: x._id, label: `${x.aname} - (Bucket ${x.bname}) - ${x.condition}`}
            });
            
            console.log(getinfo);
            console.log(xdevs);
            this.setState({
                aadhar: person,
                devices: devices,
                xdevs: xdevs,
                cdevice: getinfo,
                getback: func === 'new' ? false : true
            });
        }
        this.setState({
            showModal: true
        })
    }

    closeModal = (e) => {
        this.setState({
            showModal: false
        })
    }
    handleError(err){
    console.log(err)
    }

    changeID = (e) => {
       e.target.value = e.target.value.replace(/8\.64004E\+1[3,4](.*)labeljoy/gm, '$1');
       
       this.setState({
          id: e.target.value
       });
    }

    validateAdhaar = async(e) => {
        this.setState({
            finding: true
        });
        e.preventDefault();
        const [isvalid ] = e.target.elements;
        let valid = true;
        let response = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','bookingPeople',{"$set": {"status": parseInt(isvalid.value)}},
            {_id: {'ObjectId':isvalid.getAttribute('pid')}}
        );
        if(response.ok){
            let json = await response.json();
            if(json.ok !== undefined && json.ok){
                let data = this.state.data;
                data.people[this.state.aadhar.index].status = isvalid.value;
                
                for(var i = 0; i < data.people.length; i++){
                    if(data.people[i].status < 1){
                        valid = data.people[i].status;
                        break;
                    }else{
                        valid = data.people[i].status;
                    }
                }
                
                data.ticket.status = valid;
                const resp = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','bookings',{"$set":{"status": parseInt(data.ticket.status)}},{_id: {'ObjectId':data.ticket._id}});
                if(resp.ok){
                    json = await resp.json();
                    if(json.ok){
                        this.setState({
                            data: data,
                            finding: false
                        });
                        this.closeFrame();
                    }
                }
                
                
            }
        }
    }

    giveDevice = async (e) => {
        this.setState({
            finding: true
        });
        e.preventDefault();
        let asset; let person
       // let released = false;
        let status = 0;
        const deviceID = (e.target.elements.deviceID);
        const deviceRemarks = (e.target.elements.deviceremarks);
        if(this.state.aadhar.func === 'getback'){
            const [remarks, release] = e.target.elements;
            asset = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','assets',{"$set":{"pid":'', "status": status}},{pid: this.state.aadhar._id});
            //released = release.checked;
            if(!release.checked){
                asset = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','assets',{"$set":{"pid":this.state.aadhar._id, "status": 1}},{_id: {'ObjectId':deviceID.value}});
                status = 2;
                person = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','bookingPeople',{"$set":{"status": status, "device": deviceID.value, "remarks": deviceRemarks.value}},{_id: {'ObjectId':this.state.aadhar._id}}); // giving
            }else{
                status = 3;
                person = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','bookingPeople',{"$set":{"status": status, "device": deviceID.value, "remarks": deviceRemarks.value}},{_id: {'ObjectId':this.state.aadhar._id}}); //geting back
            }
            if(asset.ok){
                let assetremark = await this.props.app._setmongo(this.props.user.accessToken, 'insertone','assets_usage_log',{person: this.state.aadhar._id, device: deviceID.value, "status": release.checked ? 1 : 0, remarks: remarks.value, returnOn: new Date().toISOString(), entryBy: this.props.user.uid});
                if(assetremark.ok){
                    assetremark = await assetremark.json();
                     
                }else{
                    
                }
            }
        }else{
            let checkdevice = this.state.devices.some( x => x._id === deviceID.value );
            if(checkdevice){
                
                    status = 2;  
                    asset = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','assets',{"$set":{"pid":this.state.aadhar._id, "status": 1}},{_id: {'ObjectId':deviceID.value}});
                    person = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','bookingPeople',{"$set":{"status": status, "device": deviceID.value, "remarks": deviceRemarks.value}},
                    {_id: {'ObjectId':this.state.aadhar._id}}
                    ); // giving
            
            }else{
                this.setState({
                    finding: false
                });
                alert(`Device# ${deviceID.value} not found! Please check available assets.`);
            }
        }
        
        
        
        
        if(asset.ok && person.ok){
            
            person = await person.json();
            
            
            
            let data = this.state.data;
            
            data.people[this.state.aadhar.index].status = status;
            data.people[this.state.aadhar.index].device = deviceID.value;
            let bstat = false; let pstat = 0;
            for(var i = 0; i < data.people.length; i++){
                if(data.people[i].status !== status){
                    bstat = false;
                    pstat = data.people[i].status;
                    break;
                }
                bstat = true;
            }
            
            //
            //if(released){
                data.ticket.status = bstat === true ? status : pstat;
          //  }else{
           //     data.ticket.status = bstat ? 2 : 1;
           // }

            const resp = await this.props.app._setmongo(this.props.user.accessToken, 'findoneupdate','bookings',{"$set":{"status": data.ticket.status}},{_id: {'ObjectId':data.ticket._id}});
            if(resp.ok){
                let json = await resp.json();
                if(json.ok){
                    this.setState({
                        data: data,
                        finding: false
                    });
                    alert('Device Status Changed!');
                    this.closeModal();
                }else{
                    
                }
            }else{
                
            }

        }else{
            
            
        }
    }

    lockdevice = (e) => {
        if(e.target.checked){
            document.getElementById('deviceID').value = e.target.value;
            document.getElementById('btnSubmit').innerText = 'Release Device';
            document.getElementById('deviceID').setAttribute('disabled','disabled');
        }else{
            document.getElementById('btnSubmit').innerText = 'Change Device';
            document.getElementById('deviceID').removeAttribute('disabled');
        }
        
    }

    render(){
        const previewStyle = {
            height: '100%',
            width: '100%',
          }
          
        return (
        <>
            <Helmet>
                <title>Booking</title>
                <meta name='viewport' content='width=device-width,initial-scale=1,user-scalable=no, maximum-scale=1.0, shrink-to-fit=no' />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                <body id="page-top"></body>
            </Helmet>
            {
                this.state.showModal ? 
                <div className='modal-popup'>
                    <div className="contents">
                        <button className='btn btn-primary closebtn' onClick={this.closeModal}>X</button>
                        <div className='bg-dark-theme'>
                            <div className='row p-3'>
                                <div className='col-md-12'>
                                   
                                    <form onSubmit={this.giveDevice}>
                                    
                                        {
                                            this.state.getback ?
                                            <div className='form-group'>
                                                <label>Remarks (<small>Reason for changing device / If damaged condition of device on return.</small>)</label>
                                                <textarea name='remarks' className='form-control' placeholder='Reason for changing device / If damaged condition of device on return.'></textarea>
                                                <input type='checkbox' id='release' name='release' className='input-check' onClick={this.lockdevice} value={this.state.aadhar.device} /> <label htmlFor="release">Release Device</label>
                                            </div>
                                            : <></>
                                        }
                                    
                                    <div className='form-group'>
                                        <textarea className='form-control' placeholder='What is there in bucket now?' disabled={this.state.devices !== undefined && Array.isArray(this.state.devices) && this.state.devices.length < 1} id="deviceRemarks" name="deviceremarks" defaultValue={this.state.aadhar.remarks} onFocus={(e)=>{
                                            
                                        }} onBlur={(e)=>{
                                            if(e.target.value === '' && this.state.aadhar.remarks!==undefined){
                                                e.target.value = this.state.aadhar.remarks;
                                            }
                                        }} ></textarea>
                                    </div>
                                    <div className='form-group'>
                                       
                                            <div className='input-group mb-3'>
                                            
                                    <input type='text' list='devices' className='form-control' autoComplete='off' disabled={this.state.devices !== undefined && Array.isArray(this.state.devices) && this.state.devices.length < 1}
                                    placeholder={this.state.devices !== undefined && Array.isArray(this.state.devices) && this.state.devices.length < 1 ? "No Device(s) Avaialble" : "Type Device Name or ID or Scan using scanner"} id="deviceID" pid={this.state.aadhar._id} name='device' defaultValue={this.state.aadhar.device} onChange={(e)=>{
                                        e.target.value = e.target.value.replace(/8\.64004E\+1[3,4](.*)labeljoy/gm, '$1');
                                        const getinfo = this.state.devices.filter((x)=>{
                                            return x._id === e.target.value;
                                        });
                                       this.setState({
                                        cdevice: getinfo
                                       });
                                        
                                    }} onFocus={(e)=>{
                                        if(e.target.value === this.state.aadhar.device){
                                            e.target.value = '';
                                        }
                                    }} onBlur={(e)=>{
                                        if(e.target.value === '' && this.state.aadhar.device!==undefined){
                                            e.target.value = this.state.aadhar.device;
                                        }
                                    }} required />
                                    
                                    <datalist id='devices'>
                                        {
                                            this.state.devices !== undefined && Array.isArray(this.state.devices) ?
                                                this.state.devices.map((x,i)=>{
                                                    return (<option key={i} value={x._id}>Bucket# <big>{x.bname}</big> &gt; {x.aname} - ({x.aid})</option>)
                                                })
                                            : <></>
                                        }
                                    </datalist>
                                    <div className="input-group-append">
                                        <button type='submit' id='btnSubmit' data-id={this.state.aadhar} className="input-group-text bg-primary text-white">{this.state.getback ? 'Change Device' : 'Give Device'}</button>
                                    </div>
                                   
                                               </div>
                                         
                                    </div>
                                    <div id='deviceInfo'>
                                        {
                                            typeof(this.state.cdevice[0]) !== 'undefined'  ? 
                                            <div>{this.state.aadhar.pname} - Doc#{this.state.aadhar.dno} has <span className='badge badge-danger'>{this.state.cdevice[0].aname} with {this.state.cdevice[0].accessories}</span></div>
                                            : <div>Giving to {this.state.aadhar.pname} - Doc#{this.state.aadhar.dno}</div>
                                        }
                                    
                                    </div>
                                    {this.state.finding ? <span className='text-danger'>Please Wait...</span> : <></>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <></>
            }
            {this.state.showIframe ? 
            
            <div className='popupframe'>
                <div className="contents">
                    <button className='btn btn-primary closebtn' onClick={this.closeFrame}>X</button>
                    
                    {
                        /**
                     * <div className={this.state.showIframe !== '#' ? "embed-responsive embed-responsive-21by9" : 'd-none'}>
                            <iframe title='Adhaar Verifier' className="embed-responsive-item" src={this.state.showIframe} allowFullScreen></iframe>
                        </div>
                         */
                    }
                    <div className='bg-dark-theme'>
                        <div className='row p-3'>
                            <div className='col-md-12'>
                                If details matched validate here! ({this.state.aadhar.ano}: {this.props.app.getGender(this.state.aadhar.gender)} - {this.state.aadhar.age})
                                <div className='form-group'>
                                    <form onSubmit={this.validateAdhaar}>
                                        <div className='input-group mb-3'>
                                            <select className='form-control' pid={this.state.aadhar._id} name='isvalid' defaultValue={this.state.aadhar.status} required>
                                                <option value=''></option>
                                                <option value='1'>Is Valid</option>
                                                <option value='-1'>Is Invalid</option>
                                            </select>
                                        
                                            <div className="input-group-append" >
                                                <button type='submit' data-id={this.state.aadhar} className="input-group-text bg-primary text-white">validate</button>
                                            </div>
                                        </div>
                                        {this.state.finding ? <span className='text-danger'>Please Wait...</span> : <></>}
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            <></>}
            <h1 className="h3 mb-2 text-gray-800 do-not-print">Booking</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <div className='row'>
                        <div className='col-sm-9'>
                            <h6 className="m-0 font-weight-bold text-primary no-print">Scan Ticket QR CODE</h6>
                            
                        </div>
                        <div className='col-sm-3 text-center text-sm-right'>
                            {
                                this.props.params.id !== undefined ? 
                                <Link to={'/booking/edit/'+this.props.params.id} className='btn btn-primary'><i className='fa fa-edit'></i> <span className='d-md-inline d-sm-none'>Edit</span></Link> :
                                <></>
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            {this.devices !== undefined ? (<>
                                <div className='qrscanner do-not-print'>
                                    <div className="QRtv">
                                        <button className='btn btn-secondary btn-hide' onClick={this.toggleTV}>{this.state.toggleTVtext}</button>
                                        <div className={this.state.toggleTVtext === 'Show Screen' ? 'tv d-none' : 'tv'}>

                                        <QrReader
                                            delay={this.state.delay}
                                            style={previewStyle}
                                            onError={this.handleError}
                                            onScan={this.handleScan}
                                            constraints={{
                                                video: {
                                                    facingMode: {
                                                    exact: isMobile ? "environment" : "user"
                                                    }
                                                }
                                            }}
                                        />
                                        </div>
                                    </div>
                                    
                                </div>
                            </>) : (<></>)}
                            
                        </div>
                        
                        <div className='col-md-12'>
                            <div className='form-group do-not-print'>
                                <div className='input-group mb-3'>
                                    <input type='text' onChange={this.changeID} className='form-control' value={this.state.id} placeholder='Scan / Enter Booking Id' />
                                    <div className="input-group-append">
                                        <button onClick={this.getTicket} data-id={this.state.id} className="input-group-text bg-primary text-white">Submit</button>
                                    </div>
                                </div>
                                
                            </div>
                            {this.state.finding ? (<h3 className='text-center'>Searching...</h3>) : (<></>)}
                            {
                                
                                this.state.data && this.state.data.ticket !== undefined ?
                                (
                                    <div className='row'>
                                    <div className='col-md-6 do-not-print'>
                                        BookingSlot: 
                                        <h4>{this.state.data.ticket.bookingslot}</h4>
                                        bookingDate:
                                        <h4>{this.state.data.ticket.bookingDate}</h4>
                                        Status:
                                        {this.getTicketStatus(this.state.data.ticket.status)}
                                    </div>
                                    <div className='col-md-6 do-not-print'>
                                        BookedBy: 
                                        <h4>{this.state.data.user.displayName}</h4>
                                        Email: 
                                        <h4><a href={'mailto:+'+this.state.data.user.email} target='_blank' rel="noreferrer" title='Call' >{this.state.data.user.email}</a></h4>
                                        Mobile:
                                        <h4>+{__h.numfilter(this.state.data.ticket.mobile)}</h4>
                                        <a href={'tel:+'+this.state.data.ticket.mobile} target='_blank' rel="noreferrer" title='Call' >Call</a> | <a href={'https://wa.me/'+__h.numfilter(this.state.data.ticket.mobile)} target='_blank' title='Whatsapp' rel="noreferrer">Whatsapp</a>
                                        
                                        
                                    </div>
                                    <div className='col-md-12'>
                                        <hr />
                                        {
                                            Array.isArray(this.state.data.people) ?
                                                this.state.data.people.map((x,i)=>{
                                                    return (
                                                        <div id={x._id} className={this.state.spid ? 'row border-bottom pb-3 pt-3 alert-warning' : 'row border-bottom pb-3 pt-3'} key={i}>
                                                            <div className='col-md-3  align-self-center text-center'>
                                                                <strong>Person Name:</strong><br />
                                                                {x.pname} (<strong>{x.gender}</strong>)
                                                            </div>
                                                            <div className='col-md-3  align-self-center text-center do-not-print'>
                                                                <strong>Date of Birth:</strong><br />
                                                                {x.dob} ({this.props.app.getAge(x.dob)} Years)<br />
                                                                
                                                            </div>
                                                            <div className='col-md-3  align-self-center text-center do-not-print'>
                                                            <strong>{x.nationality === 'IN' ? 'Adhaar' : 'Passport'} ID:</strong><br />
                                                                {x.dno}
                                                                {
                                                                    this.state.data.ticket.status === "-2" ? 
                                                                    <><span className='text-danger fa fa-close'></span> CANCELLED</> 
                                                                    : <>
                                                                    {
                                                                        x.nationality === 'IN' ? 
                                                                        <div>
                                                                        {
                                                                            x.status <= 2 ?
                                                                            <span style={{'cursor':'pointer'}} className={this.isValid(x.status) ? 'badge badge-success' : 'badge badge-primary'}
                                                                                onClick={this.showFrame}
                                                                                    adetail={JSON.stringify({index:i, _id: x._id, ano: x.dno, gender: x.gender, status:x.status, age: this.props.app.getAge(x.dob) + ' Years'})}
                                                                                        url='https://myaadhaar.uidai.gov.in/verifyAadhaar' target='_blank'>
                                                                                            {this.isValid(x.status) ? 'Validated' : 'Validate Doc' + x.status}
                                                                            </span> :
                                                                            <><span className={x.status < 0 ? 'text-danger fa fa-ban' : 'text-success fa fa-check'}></span></>
                                                                        }
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {x.status <= 2 ? <button className={this.isValid(x.status) ? 'btn btn-success' : 'btn btn-primary'} onClick={this.showFrame} adetail={JSON.stringify({index:i, _id: x._id, ano: x.dno, gender: x.gender, status:x.status, age: this.props.app.getAge(x.dob) + ' Years'})} url='#' target='_blank'>{this.isValid(x.status) ? 'Verified' : 'Verify Passport'}</button> : <><span className='text-success fa fa-check'></span></>}
                                                                        </div>
                                                                    }
                                                                    </>
                                                                    
                                                                }
                                                                
                                                            </div>
                                                            <div className='col-md-3  align-self-center text-center'>
                                                                {
                                                                        this.state.data.ticket.status === "-2" ? 
                                                                        <><span className='text-danger fa fa-close'></span> CANCELLED</> 
                                                                        : <>
                                                                            {this.getDeviceStatus(x.status, i)}
                                                                        </>
                                                                }
                                                                
                                                            </div>
                                                        </div> 
                                                    )   
                                                })
                                            : (<></>)
                                        }
                                    </div>
                                </div>
                                )
                                : (<>{this.state.found}</>)
                            }
                            
                        </div>
                    </div>

                </div>
            </div>
        </>
        )
    }
    
}

export default withParams(Booking)