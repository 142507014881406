import * as React from 'react';
import QrReader from 'react-qr-scanner'
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Helpers from '../../Helpers';
const __h = new Helpers();

class Assets extends React.Component{
    constructor(props){
        super(props);
        this.settings = []
        this.state = {
            pModal: false,
            qrcode: '',
            assets: [],
            person: {},
            settings: this.settings
        }
        
        
    }

    async componentDidMount(){
        this.settings = await this.props.app.getSettings();
        this.setState({
            settings: this.settings.BookingStatus !== undefined ? this.settings.BookingStatus : this.settings
         });
        this.getAssets();
    }

    getTicketStatus = (n = false) => {
        n = n === undefined ? -1 : parseInt(n);
        let status = this.state.settings.filter((x)=>{
            return x.status === n;
        });
        
        let text;
        if(status.length > 0){
            status = status[0];
            text = <span className={`badge badge-${status.type}`}>{status.msg}</span>
        }
        return text;
    }

    handleScan = async (data) => {
        if(data !== null){
            
            if(this.state.id !== data.text)
            {
                this.setState({
                    qrcode: data.text
                });
                const ele = document.querySelector('#d_' + data.text);
                if(ele instanceof HTMLElement){
                    ele.click();
                }
                
                //await this.getpersoninfo(data.text);
                var beepSound = new Audio('./assets/beep.mp3');
                beepSound.loop = false;
                beepSound.play();
            }
            
        }
    }

    findAsset = async (e) => {
       
        const ele = document.querySelector('#d_' + e.target.value);
        if(ele instanceof HTMLElement){
            ele.click();
        }
        
        
    }

    getpersoninfo = async (pid) => {
        this.setState({
            pModal: true
        });
        
        let token = this.props.user.accessToken;
        let person = await this.props.app._getmongo(token,'findone','bookingPeople',{_id: {'objectid':pid}});
        if(person.ok){
            person = await person.json();
            
            this.setState({
                person: person
            });
            if(person.fid !== undefined){
                let booking = await this.props.app._getmongo(token,'findone','bookings',{_id: {'objectid':person.fid}});
                if(booking.ok){
                    booking = await booking.json();
                    person.booking = booking;
                }
                this.setState({
                    person: person
                });
                
            }
        }
    }

    handleError(err){
        console.log(err)
        }

    

    

    filterAssets = async(e) => {
        let fltr = document.getElementById('filterAsset').value;
        
        if(fltr !== null && fltr !== undefined && fltr.length > 0){
            this.getAssets({
                $text: {$search: fltr}
            });
        }else{
            this.getAssets();
        }
    }
    getAssets = async(filter = {}, sort = {status: -1, inserted: -1}) => {
        let token = this.props.user.accessToken;
        const response = await this.props.app._getmongo(token,'sortfind','assets',filter,sort);

        if(response.ok){
            this.assets = await response.json();
            this.assets.summary = {
                total: this.assets.length,
                allocated: this.assets.filter((x)=>{
                    return typeof(x.pid) !== 'undefined' && x.pid.length > 0;
                }).length,
                available: this.assets.filter((x)=>{
                    return typeof(x.pid) === 'undefined' || x.pid.length < 1;
                }).length,
            }
            
            this.setState({
                assets: this.assets
            })
        }
    }

    showPerson =  async(e) => {
        this.setState({
            pModal: true
        });
        const pid = e.target.innerText;
        const data = e.target.getAttribute('data');
        
        let token = this.props.user.accessToken;
        let person = await this.props.app._getmongo(token,'findone','bookingPeople',{_id: {'objectid':pid}});
        if(person.ok){
            person = await person.json();
            this.setState({
                person: person
            });
            if(person.fid !== undefined){
                let booking = await this.props.app._getmongo(token,'findone','bookings',{_id: {'objectid':person.fid}});
                if(booking.ok){
                    booking = await booking.json();
                    person.booking = booking;
                }
                this.setState({
                    person: person
                });
                
            }
        }
    }

    render(){
        const previewStyle = {
            height: '100%',
            width: '100%',
          }
          
        return (
        <>
            <Helmet>
                <title>Assets</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                
                <body id="page-top"></body>
            </Helmet>
            <div className='qrscanner do-not-print d-none'>
            <div className="QRtv">
                <button className='btn btn-secondary btn-hide' onClick={this.toggleTV}>{this.state.toggleTVtext}</button>
                <div className={this.state.toggleTVtext === 'Show Screen' ? 'tv d-none' : 'tv'}>

                <QrReader
                    delay={this.state.delay}
                    style={previewStyle}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    constraints={{
                        video: {
                            facingMode: {
                            exact: isMobile ? "environment" : "user"
                            }
                        }
                    }}
                />
                </div>
            </div>
            
        </div>
            {this.state.pModal ?
            (<div className='modal-popup'>
                <div className='contents p-5'>
                    <button className='closebtn' onClick={()=>{this.setState({pModal:false})}}>X</button>
                    
                    { this.state.person && Object.keys(this.state.person).length > 0 ? 
                    (<div className='row'>
                        { this.state.person.booking && Object.keys(this.state.person.booking).length > 0 ? (<>
                            <div className='col-md-6'>
                                BookingID
                                <h5><Link to={'/booking/' + this.state.person.booking._id} >{this.state.person.booking._id}</Link></h5>
                            </div>
                            <div className='col-md-6'>
                                Booking Slot
                                <h5><Link to={'/booking/' + this.state.person.booking._id} >{this.state.person.booking.bookingslot}</Link></h5>
                            </div>
                            <div className='col-md-6'>
                                BookingBy &amp; Mobile<br />
                                <Link to={'/user/' + this.state.person.booking.uid} >{this.state.person.booking.uid === null ? 'Booking Office' : this.state.person.booking.uid}</Link>
                                <br />
                                <a href={'tel:' + this.state.person.booking.mobile.replace('-','')} target="_blank" rel="noreferrer">{this.state.person.booking.mobile}</a> | <a href={'https://wa.me/' + this.state.person.booking.mobile.replace('-','')} target="_blank" rel="noreferrer">Whatsapp</a>

                            </div>
                            <div className='col-md-6'>
                                Booking Status
                                <h5>{this.getTicketStatus(this.state.person.booking.status)}</h5>
                            </div>
                        </>):<></>}
                        <div className='col-md-3 text-md-right'>Person Name &amp; (Gender-Age)</div>
                        <div className='col-md-9'>{this.state.person.pname} ({this.state.person.gender}-{__h.getAge(this.state.person.dob)}Y)</div>
                        <div className='col-md-3 text-md-right'>Nationality &amp; (DocumentNo)</div>
                        <div className='col-md-9'>{this.state.person.nationality} ({this.state.person.dno})</div>
                        <div className='col-md-3 text-md-right'>Device Info &amp; (Status)</div>
                        <div className='col-md-9'>{this.state.person.device} ({this.getTicketStatus(this.state.person.status)})</div>
                    </div>):(<></>) }
                </div>
            </div>)
            :(<></>)}
            
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className='row'>
                        <div className="col-md-9">
                            <h1 className="h3 mb-2 text-gray-800">Buckets</h1>
                        </div>
                        <div className='col-md-3 text-right'>
                        <Link to='/asset/new' className='btn btn-primary'>Add New</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className='searchbox'>
                        <div className='input-group mb-3'>
                            <input type='text' onChange={this.changeID} id="filterAsset" className='form-control' onBlur={this.filterAssets} placeholder='Find Asset' />
                            <div className="input-group-append">
                                <button onClick={this.filterAssets} data-id={this.state.id} className="input-group-text bg-primary text-white">Find</button>
                            </div>
                        </div>
                        {
                            this.state.assets.summary !== undefined ? 
                            <div><strong>Total: </strong>{this.state.assets.summary.total} <strong>Allocated: </strong>{this.state.assets.summary.allocated} <strong>Available: </strong>{this.state.assets.summary.available}</div>
                            :<></>
                        }
                        
                    </div>
                    <div className="table-responsive">
                        {
                            Array.isArray(this.state.assets) && this.state.assets.length > 0 ? 
                            <>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <td>Bucket</td>
                                        <td>Device</td>
                                        <td>Serial / IMEI</td>
                                        <td>Condition</td>
                                        <td>GivenTo</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.assets.map((x)=>{
                                            return (
                                                <tr key={x._id}>
                                                    <td>{x.bname}</td>
                                                    <td>{x.aname}<br /><small>({x.accessories})</small><div>({x._id})</div></td>
                                                    <td>{x.aid}</td>
                                                    <td>{x.condition}</td>
                                                    <td><br />{__h.notEmptyComponent(x.pid, <button onClick={this.showPerson} id={"d_" + x._id} data={{name: x.aname}} className='btn btn-danger btn-sm'>{x.pid}</button>)}</td>
                                                    <td><Link to={'/asset/edit/' + x._id} className='btn btn-theme' >Edit</Link></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            </>
                            :
                            (<></>)
                        }
                    </div>
                </div>
            </div>
        </>
        )
    }
    
}

export default Assets