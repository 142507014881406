import * as React from 'react';
import AuthControl from '../controllers/AuthControl';

class Layout extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      user: false
    }
    this.getUser();
  }
  
  componentDidMount() {
    
     this.getUser();
     window.addEventListener('load', this.getUser, true);
   }
 
   getUser = async () => {
     const user = await this.props.app.syncUser();
     //
     this.setState({
       user: user
     });
   
   }
   
   componentWillUnmount() {
     
     window.removeEventListener('load', this.getUser);
   }
   
   render() {
      
      if(this.state.user === false){
        this.getUser();
        return <><div className='preloader'><img src='./assets/sssct_logo.png' alt='sssct' /><br />Validating...</div></>
      }
      return (
          <AuthControl app={this.props.app}  user={this.state.user} ></AuthControl>
      );
    }
  }
  export default Layout;