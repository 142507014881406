import React from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
//import $ from "jquery";
//import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

import Helpers from '../../../Helpers';
import { Link } from "react-router-dom";
const __h = new Helpers();
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class Visitors extends React.Component {

    constructor(props) {
        super(props);
        this.query = this.props.app.query;
        this.settings = [];
        this.state = {
            loading: true,
            visitors: [],
            devices: [],
            device: {},
            booking: [],
            show: false,
            csv: [],
            params: this.query,
            settings: this.settings
        }

    }

    async componentDidMount(){

        this.settings = await this.props.app.getSettings();
        this.setState({
           settings: this.settings.BookingStatus !== undefined ? this.settings.BookingStatus : this.settings
        });
        let aggregate = [];
        if (Object.keys(this.query).length > 0) {
            if (this.query.fromdate !== undefined) {
                aggregate.push({
                    '$match': {
                        '$expr': {
                            '$gte': [
                                {
                                    '$dateFromString': {
                                        'dateString': '$bookingslot'
                                    }
                                }, {
                                    '$dateFromString': {
                                        'dateString': new Date(this.query.fromdate)
                                    }
                                }
                            ]
                        }
                    }
                });
            }
            if (this.query.todate !== undefined) {
                aggregate.push({
                    '$match': {
                        '$expr': {
                            '$lte': [
                                {
                                    '$dateFromString': {
                                        'dateString': '$bookingslot'
                                    }
                                }, {
                                    '$dateFromString': {
                                        'dateString': new Date(this.query.todate)
                                    }
                                }
                            ]
                        }
                    }
                });
            }
        }
        aggregate.push({
            '$addFields': {
                '_fid': {
                    '$toString': '$_id'
                },
                'sortfirst': {
                    '$cond': [
                        {
                            '$gte': [
                                { $toInt: '$status' }, 3
                            ]
                        }, -10, { $toInt: '$status' }
                    ]
                }
            }
        });
        aggregate.push({
            '$lookup': {
                'from': 'brindavan_bookingPeople',
                'localField': '_fid',
                'foreignField': 'fid',
                'as': 'people'
            }
        });
        aggregate.push({
            $limit: this.query.limit !== undefined ? parseInt(this.query.limit) : 5000
        });
        aggregate.push({
            $sort: {
                'sortfirst': -1,
                'bookingslot': -1
            }
        });
        await this.getVisitors(aggregate);
    }

    getTicketStatus = (n) => {
        n = n === undefined ? -1 : parseInt(n);
        let status = this.state.settings.filter((x)=>{
            return x.status === n;
        });
        
        let text;
        if(status.length > 0){
            status = status[0];
            text = <span className={`badge badge-${status.type}`}>{status.msg}</span>
        }
        return text;
    }

    getDevices = async () => {
        let token = this.props.user.accessToken;
        let adevices = await this.props.app._getmongo(token, 'find', 'assets', {}, { $natural: 1 });
        let devices = [];
        if (adevices.ok) {
            adevices = await adevices.json();
            if (Array.isArray(adevices)) {
                adevices.forEach((x) => {
                    devices[x._id] = x;
                })
            }
            
            this.setState({
                devices: devices
            });
        }
    }

    getVisitors = async (filter = {}) => {
        await this.getDevices();
        let token = this.props.user.accessToken;
        let booking = await this.props.app._getmongo(token, 'aggregate', 'bookings', filter);
        
        let visitors = [];

        if (booking.ok) {
            booking = await booking.json();
            
            if (Array.isArray(booking) && booking.length > 0) {
                booking.forEach((x) => {
                    if (Array.isArray(x.people)) {
                        x.people.forEach((p) => {
                            p.slot = x.bookingslot;
                            p.bdate = x.bookingDate;
                            p.btype = __h.admins().includes(x.uid) ? 'Offline' : 'Online';
                            visitors.push(p);
                        })
                    }

                });

            }
            
            let edata = __h.formatExport(visitors, {
                pname: 'Name',
                dob: 'Birthdate',
                gender: 'Gender',
                nationality: 'Nationality',
                btype: 'BookingType',
                slot: 'Slot',
                bdate: 'BookingDate',
                status: 'Status'
            });
            edata = {
                data: edata.data,
                headers: edata.header,
                filename: `Report_sssdivyasmrti_${new Date().toLocaleString().replace(/\s|[ _]|\W|[#$%^&*()]/g, '_')}.csv`
            };
            
            this.setState({
                csv: edata,
                loading: false,
                visitors: visitors
            })
        } else {
            this.setState({
                csv: [],
                loading: false,
                visitors: visitors
            });
            
            alert('Error ');
        }

    }

    showBooking = async (e) => {
        let data = e.target.getAttribute('data');
        data = JSON.parse(data);

        let id = data.fid !== undefined ? data.fid : false

        let token = this.props.user.accessToken;
        let booking = await this.props.app._getmongo(token, 'findone', 'bookings', { _id: { 'objectID': id } });
        if (booking.ok) {
            booking = await booking.json();
            let people = await this.props.app._getmongo(token, 'find', 'bookingPeople', { fid: id });
            if (people.ok) {
                people = await people.json();
                booking['people'] = people;
            }
            this.setState({
                show: 'booking',
                booking: booking
            })
        }
        
        
    }

    showDevice = async (e) => {
        await this.getDevices();
        let id = e.target.getAttribute('data');
        
        this.setState({
            show: 'asset',
            device: this.state.devices[id]
        })
    }



    render() {
        return (<>
            <Helmet>
                <title>Report &gt; Visitors</title>
                <meta name='viewport' content='width=device-width,initial-scale=1,user-scalable=no, maximum-scale=1.0, shrink-to-fit=no' />
                <meta name="description" content="" />
                <meta name="keywords" content="" />

                <body id="page-top"></body>
            </Helmet>
            {this.state.show ?
                (<div className='modal-popup'>
                    <div className='contents p-5'>
                        <button className='closebtn' onClick={() => { this.setState({ show: false }) }}>X</button>

                        <div className="row">
                            <div className="col-md-12">
                                {
                                    <>
                                        <h1 className="text-center" style={{ textTransform: 'capitalize' }}>{this.state.show} Detail</h1>
                                        <hr />
                                    </>
                                }
                                {
                                    this.state.show === 'booking' ?
                                    <>
                                    <div className="row">
                                        <div className="col-12 text-center">{this.getTicketStatus(this.state.booking.status)}<hr /></div>
                                        <div className="col-md-6">
                                        <strong>Booking ID:</strong> <Link to={'/booking/'+this.state.booking._id}>{this.state.booking._id}</Link>
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>Booking Date:</strong> {this.state.booking.bookingDate}
                                        </div>
                                        <div className="col-md-6 ">
                                                <strong>Booking Slot:</strong> {this.state.booking.bookingslot}
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>Mobile:</strong> {this.state.booking.mobile}
                                            
                                        </div>
                                        <div className="col-md-12">
                                                {this.state.booking.people instanceof Array ?
                                                    <div className="table-responsive">
                                                        <hr />
                                                        <h4 className="text-center">Accompanying {this.state.booking.people.length} Persons</h4>
                                                        <hr />
                                                        <table className="table table-hover table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th className="text-center">Name</th>
                                                                    <th className="text-center">Gender</th>
                                                                    <th className="text-center">Birthdate</th>
                                                                    <th className="text-center">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.booking.people.map((x,i) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{(i+1)}</td>
																				
																				<td className="text-center">{x.pname}</td>
																				<td className="text-center">{x.gender}</td>
																				<td className="text-center">{x.dob}</td>
																				<td className="text-center">{this.getTicketStatus(x.status)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    : <></>}
                                            </div>
                                    </div>

                                    </>    
                                    : <></>
                                }
                                {
                                    this.state.show === 'asset' ?
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <strong>Device ID:</strong> {this.state.device._id}
                                            </div>
                                            <div className="col-md-6 ">
                                                <strong>Device:</strong> {this.state.device.aname}
                                            </div>
                                            <div className="col-md-6 ">
                                                <strong>Badge:</strong> {this.state.device.bname}
                                            </div>
                                            <div className="col-md-6 ">
                                                <strong>Condition:</strong> {this.state.device.condition}
                                            </div>
                                            <div className="col-md-6 ">
                                                <strong>Serial / IMEI :</strong> {this.state.device.aid}
                                            </div>
                                            <div className="col-md-6 ">
                                                <strong>Accessories:</strong> {this.state.device.accessories}
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>)
                : (<></>)}
            <h1 className="h3 mb-2 text-gray-800 do-not-print">Visitors</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Museum Visitors</h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <form>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        {__h.formControl('fromdate', 'From Date', 'date', {
                                            placeholder: 'From Date',
                                            required: 'required',
                                            onClick: __h.showPicker,
                                            defaultValue: this.state.params['fromdate']
                                        })}
                                    </div>
                                    <div className='col-sm-5'>
                                        {__h.formControl('todate', 'To Date', 'date', {
                                            placeholder: 'From Date',
                                            required: 'required',
                                            onClick: __h.showPicker,
                                            defaultValue: this.state.params['todate']
                                        })}
                                    </div>
                                    <div className='col-sm-2'>
                                        <br />
                                        {__h.formControl('btnsubmit', 'Find', 'button', {
                                            className: 'btn btn-danger',
                                            type: 'submit'
                                        })}
                                        &nbsp;
                                        {__h.formControl('btnreset', 'Reset', 'button', {
                                            className: 'btn btn-info',
                                            onClick: () => { window.location.href = window.location.protocol + '//' + window.location.hostname + (window.location.port ? `:${window.location.port}` : '') + '/report/visitors' },
                                            type: 'reset'
                                        })}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-12">
                            {
                                this.state.loading ?
                                    <>Loading...</> :
                                    <>
                                        {
                                            Array.isArray(this.state.visitors) && this.state.visitors.length > 0 ?
                                                <div className="table-responsive">
                                                    <div className="text-right">
                                                        <CSVLink className="btn btn-sm btn-success" {...this.state.csv}>Export <i className="fa fa-file-excel"></i></CSVLink>
                                                    </div>
                                                    <hr />
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>

                                                                <th className="text-center">Name</th>
                                                                <th className="text-center">Gender</th>
                                                                <th className="text-center">Birthdate (Age)</th>
                                                                <th className="text-center">Nationality</th>
                                                                <th className="text-center">Detail</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.visitors.map((x, i) => {
                                                                    let age = this.props.app.getAge(x.dob);
                                                                    let device = this.state.devices[x.device];
                                                                    let used;
                                                                    switch (x.status) {
                                                                        case -1:
                                                                            used = "Not Checked in";
                                                                            break;
                                                                        case 0:
                                                                            used = "Entered";
                                                                            break;
                                                                        case 1:
                                                                            used = "Waiting for device";
                                                                            break;
                                                                        case 2:
                                                                            used = "Has ";
                                                                            break;
                                                                        case 3:
                                                                            used = "Had ";
                                                                            break;
                                                                        default:
                                                                            used = "";
                                                                            break;
                                                                    }
                                                                    return (
                                                                        <tr key={i}>
                                                                        <td>{(i + 1)}</td>
                                                                        <td className="text-center">{__h.notEmptyComponent(x._id, <span style={{cursor:'pointer'}} onClick={this.showBooking} id={"p_" + x._id} data={JSON.stringify(x)} className='text-info'>{x.pname}</span>)}<br />{this.getTicketStatus(x.status)}
                                                                        {device !== undefined ? <span style={{cursor:'pointer'}} onClick={this.showDevice} id={"d_" + x.device} data={x.device} className={used === 'Has ' ? 'badge badge-danger' : 'badge badge-info'}>{used}{device.aname}</span> : <></>}
                                                                        </td>
                                                                        <td className="text-center">{x.gender}</td>
                                                                        <td className="text-center">{
		age < 15 ? <span className="text-danger">{x.dob} ({age})</span> : <span className="text-info">{x.dob} ({age})</span>
	}</td>
    <td className="text-center">{x.nationality}</td>
    <td className="text-center">
		Booked {x.btype}@{x.bdate}<br />
		Slot: {x.slot}<br />
		booking#: <Link to={'/booking/' + x.fid}>{x.fid}</Link><br />
		
	</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <hr />
                                                    <div className="text-right">
                                                        <CSVLink className="btn btn-sm btn-success" {...this.state.csv}>Export <i className="fa fa-file-excel"></i></CSVLink>
                                                    </div>
                                                </div> :
                                                <>No data Available</>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

}

export default withParams(Visitors);
