import Global from "./Global";

class Init extends Global {
    
    
    
    syncUser = async() => {
        return await this.authUser();
    }

    isPromise = (p) => {
        if (typeof p === 'object' && typeof p.then === 'function') {
            return true;
        }
        return false;
    }


    _setmongo = async (token, action, col, data, qry = '') => {
        /* 
         'createCollection',dropCollection,insertone,insertmany 
         updateone updatemany deleteone 'deletemany': */
        const response = this.postReq(token, "/set",{post: {
            action: action,
            collection: this.prefix(col),
            qry: qry,
            data: data
        }});
        return await response;
    }

    _getmongo = async (token, action, col, qry, order = {}, limit = 100, skip = 0) => {
        /*
        sortFind,joinfind,find,findone
        */
        return await this.postReq(token, "/get",{ post: {
            action: action,
            collection: this.prefix(col),
            qry: qry,
            order: order,
            limit: limit,
            skip: skip
        }});
         
    }

    getReq = async (token, req,  params = {}, contentType = false) => {
        const host = params.host !== undefined ? params.host : this.apiHost;
        const headers = params.headers !== undefined ? params.headers : this.headers;
        headers.authtoken = token;
        if(contentType){
            headers['Content-Type'] = contentType;
        }
        console.log({
            GET: {request: `${host} + ${req}`, header: headers, params: params}
        });
        const body = params.post;
        let response = await fetch(host + req + new URLSearchParams(body), {
		  method: "GET",
		  headers: headers
		});
        if(response.ok){
            return await response.json();
        }else{
           // alert('Error: ' + response.status);
            
            return response;
        }
    }

    responseResult = async (response) => {
        
        if(response.ok){
            //
            return await response.text();
        }else{
           // alert('Error: ' + response.status);
            //
            return await response;
        }
    }

      postReq = async (token, req, params = {}, contentType = false) => {
        const host = params.host !== undefined ? params.host : this.apiHost;
        
        const headers = params.headers !== undefined ? params.headers : this.headers;
        headers.authtoken = token;
        if(contentType){
            headers['Content-Type'] = contentType;
        }
        console.log({
            POST: {request: `${host} + ${req}`, header: headers, params: params}
        });
        
        const body = params.body !== undefined ? params.body : JSON.stringify(params.post);
        let response = await fetch(host + req, {
            method: "POST",
            headers: headers, 
            body: body
        });
        return response;
        /*  if(response.ok){
              return await response.json();
          }else{
             // alert('Error: ' + response.status);
              
              return response;
          }
          */
      }

}
export default (Init);