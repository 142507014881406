import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class Media extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            files: []
        }
        this.getFileList();
    }

    getFileList = async() => {
        let token = this.props.user.accessToken;

        let filelist = await this.props.app._getmongo(token, 'find', 'media', {});
        if(filelist.ok){
            filelist = await filelist.json();
        }
        //let files = await this.props.app.fileList(token);
      
        this.setState({
            files: filelist
        });
        
        
        /*for(var i=0; i < files.items.length; i++){
            files.items[i].fileUrl = await this.props.app.displayImage(files.items[i]);
            this.setState({
                files: files.items
            });
        }
        console.log(files)*/
    }

   /* removeImage = async(e) => {
        let data = e.target.getAttribute('dataset');
       // let token = this.props.user.accessToken;
        data = JSON.parse(data);
       // let del = await this.props.app.fileDelete(data.id, data.file, data.path);
        
        
    }*/

    render(){
        return(<>
            <Helmet>
                <title>Media</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                        "\n .mbox{\n position: relative;\n }\n .btn-delete{\n position: absolute;\n top:2px;\n right:2px;\n }\n"
                    }}
                />
                
                <body id="page-top"></body>
            </Helmet>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className='row'>
                        <div className="col-md-9">
                            <h1 className="h3 mb-2 text-gray-800">Media</h1>
                        </div>
                        <div className='col-md-3 text-right'>
                        <Link to='/media/new' className='btn btn-primary'>Add New</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {
                            this.state.files.map((x, i)=>{
                                return (<div key={i} className="mbox col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 align-self-center text-center m-2">
                                    {x.url !== undefined ? <div title="Delete Image" onClick={this.removeImage}  className="overlay"><button dataset={JSON.stringify({id:x._id,file:x.filename,path:x.filepath})} className="btn-delete btn btn-sm btn-dark">X</button><img style={{maxWidth:'100%'}} alt="" src={x.url} /></div> : <div>Loading Preview...</div>}
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>
        </>);
    }
}

export default Media;