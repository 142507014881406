import * as React from 'react';

import { Helmet } from "react-helmet";
import '../../assets/vendor/fontawesome-free/css/all.min.css';
import '../../assets/css/sb-admin-2.css';
import { Link } from 'react-router-dom';

class ResetPassword extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {  
            err: '',
            res: '',
        };
        
    }
    
    doReset = async (e) => {
        e.preventDefault();
        const { email } = e.target.elements;

        this.props.app.resetPassword(email.value).then((res)=>{
            
            this.setState({  
                err: '',
                res: 'Password Reset Link Sent!'
              });
        }).catch((err)=>{
           
           this.setState({  
            err: this.props.app.fError(err.message),
            res: ''
          });
          
        });

    }

    render() {
      
      return (
        <div>
            <Helmet>
          
                <title>Reset Password</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
                
                </Helmet>
                <div className="login-container  bg-gradient-primary">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-xl-10 col-lg-12 col-md-9">

                                <div className="card o-hidden border-0 shadow-lg my-5">
                                    <div className="card-body p-0">
                                        
                                        <div className="row">
                                            <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                                <img alt='Sai' title='Sai' src='https://beta.prasanthinilayam.in/images/swamisitting.jpg' style={{maxWidth:'100%'}} />
                                            </div>
                                            <div className="col-lg-6 align-self-center">
                                            <div class="p-5">
                                                <div class="text-center">
                                                    <h1 class="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                                    <p class="mb-4">We get it, stuff happens. Just enter your email address below
                                                        and we'll send you a link to reset your password!</p>
                                                </div>
                                                <form class="user" onSubmit={this.doReset} >
                                                    <div class="form-group">
                                                        <input type="email" class="form-control form-control-user"
                                                            id="email" name='email' aria-describedby="emailHelp"
                                                            placeholder="Enter Email Address..." required />
                                                    </div>
                                                    <div className={this.state.err !== '' ? 'alert alert-danger' : '' }>{this.state.err}</div>
                                                        <div className={this.state.res !== '' ? 'alert alert-success' : '' }>{this.state.res}</div>
                                                    <button type='submit' class="btn btn-primary btn-user btn-block">
                                                        Reset Password
                                                    </button>
                                                </form>
                                                <hr />
                                                
                                                <div class="text-center">
                                                    <Link class="small" to="/login">Already have an account? Login!</Link>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <script src="./assets/vendor/jquery/jquery.min.js"></script>
                    <script src="./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                    <script src="./assets/vendor/jquery-easing/jquery.easing.min.js"></script>
                    <script src="./assets/js/sb-admin-2.min.js"></script>

                </div>
        </div>
      );
    }
  }
  export default ResetPassword;