import { signInWithEmailAndPassword, signOut, setPersistence, browserSessionPersistence, sendPasswordResetEmail } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL, listAll, list, deleteObject  } from "firebase/storage";
import Init from "./Init";

class Controller extends Init{

    async getInbox(){
        //{"num_inboxes":1,"inboxes":[{"id":10,"number":919220592205,"keyword":"XM498","num_messages":0,"num_contacts":0,"inboxName":"","newMsgs":0,"isMyDND":"n"}],"status":"success"}
        const api = this.env.textlocal.api;
        const inbox = this.env.textlocal.inbox;
        const addMinutes = function (m = 1, dt = false){
            dt = dt ? dt : new Date();
            dt.setMinutes( dt.getMinutes() + m );
            return dt;
        }
        var min_time = Math.floor(addMinutes(-5).getTime() / 1000);
        var max_time = Math.floor(addMinutes(5).getTime() / 1000);
        const req = `/get_messages/?apikey=${api}&inbox_id=${inbox}&limit=100&min_time=${min_time}&max_time=${max_time}`;
        const host = this.env.textlocal.host;
        const response = await fetch(host + req, {
            method: "GET"
        }).then((res)=>{
          if(res.ok){
            return res.json();
          }
        });
        this.smsinbox = await response;
      }

      filterDuplicate(arr){
        let f = [];
        let v;
        arr.forEach((e) => {
          if(JSON.stringify(e) !== JSON.stringify(v)){
            f.push(e);
          } 
          v = e;
        });
    
        return Array.isArray(f) && f[0] !== undefined ? f[0] : f;
      }

      async processBooking(token, data, b_id = false){
        const uid = this.user.uid;
        
        
          
          //if(slot !== null && people.length > slot.available){
           // this.bookingValidation = 'Number of people are more than allowed per slot';
         // }else{
            let bkdata = {
              prefix: this.prefix(''),
              bookingSlot: data.date + ' ' + data.slot,
              date: data.date,
              slot: data.slot,
              uid: uid,
              people: data.people,
              mobile: data.mobile,
              b_id: b_id
            }
                
                let response = this.postReq(token, !b_id ? '/dobooking' : '/updatebooking',{post: bkdata});
                return await response;
         // }
          
          //data.forEach((row)=>{
    
         // });
        
    
      }

    listAllUsers = async(token) => {
        return await this.getReq(token, '/users');
        //
    }

    getFUser = async(token, uid) => {
        let resp = await this.getReq(token, '/user?id=' + uid);
        //
        return resp;
        //return await this.getReq(token, '/user');
    }

    listFiles = async(path = 'images/', filename = '') => {
        const listRef = ref(this.storage, path + filename);
        let resp = await listAll(listRef)
        .then((res) => {
            let files = [];
            let index = 0;
            res.prefixes.forEach((folderRef) => {
                files[index]['folder'] = folderRef;
                
                index++;
            });
            index = 0;
            res.items.forEach((itemRef) => {
            // All the items under listRef.
                this.displayImage(itemRef);
                files[index]['item'] = itemRef;
                
                index++
            });
            
            return files;
        }).catch((error) => {
            
            return error;
            // Uh-oh, an error occurred!
        });
        return resp;
    }
    

    async displayImage(imageRef) {
        return await getDownloadURL(imageRef).then(function(url) {
            return url;
            // TODO: Display the image on the UI
        }).catch(function(error) {
            
        });
    }

    fileList = async (token, path = 'images/') => {
        
        // Create a reference under which you want to list
        
        const listRef = ref(this.storage, path);
        
        // Fetch the first page of 100.
        const listdata = await list(listRef, { maxResults: 100 });
        
        // Use the result.
        // processItems(firstPage.items)
        // processPrefixes(firstPage.prefixes)
        
        // Fetch the second page if there are more elements.
        /*if (firstPage.nextPageToken) {
            const secondPage = await list(listRef, {
            maxResults: 100,
            pageToken: firstPage.nextPageToken,
            });
            // processItems(secondPage.items)
            // processPrefixes(secondPage.prefixes)
        }*/
        return listdata;
    }

    fileDelete = async (id, file, path = 'images/') => {
        const desertRef = ref(this.storage, path + file);
        let deleted = await this._setmongo(this.user.accessToken, 'deleteone', 'media', {_id: {'objectid':id}});
        if(deleted.ok){
            deleted = await deleted.json();
            return await deleteObject(desertRef).then(() => {
                return deleted;
            }).catch((error) => {
                
                return false;
            // Uh-oh, an error occurred!
            });
        }else{
            return deleted;
        }
        // Delete the file
        
    }

    fileUpload = async (token, file, path = 'images/', allowed = ['image/png','image/jpeg','image/jpg','image/gif','application/pdf']) => {
        if(allowed.includes(file.type)){
            const imagesRef = ref(this.storage,path + file.name);
        
            const metadata = {
                contentType: file.type,
            };
            const upload = uploadBytes(imagesRef, file, metadata);
            const result = await upload.then((res)=>{
                
                return getDownloadURL(res.ref).then((url) => {
                    const response = this._setmongo(token,'insertone','media', {
                        uploadDate: new Date(),
                        filepath: path,
                        filename: file.name,
                        url: url,
                        type: file.type
                    });
                    return response.then((jsResp)=>{
                        if(jsResp.ok){
                           return jsResp.json().then((rjs)=>{
                                rjs['url'] = url;
                                return rjs;
                           });
                           
                        }else{
                            return false;
                        }
                        
                    })
                    // 
                    }).catch((error) => {
                        
                        return error;
                    });
                /**/
            }).catch((err)=>{
                
            })
            
            
            return result;
            //
        }else{
            return false;
        }
        
    }

    signInPassword = async (email, pwd, check = false) => {
        if(check){
           return await setPersistence(this.fAuth, browserSessionPersistence)
            .then(() => {
                return signInWithEmailAndPassword(this.fAuth, email, pwd);
            });
        }else{
            return await signInWithEmailAndPassword(this.fAuth, email, pwd);
        }
        
    }

    resetPassword = async (email) => {
        return await sendPasswordResetEmail(this.fAuth, email);
    }

    signOut = async() => {
        return await signOut(this.fAuth);
    }
}

export default Controller;