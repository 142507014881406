import * as React from 'react';
import { Helmet } from "react-helmet";
import Database from '../../controllers/Database';

Array.prototype.sum = function (prop) {
    var total = 0
    for ( var i = 0, _len = this.length; i < _len; i++ ) {
        total += this[i][prop]
    }
    return total
}

class BookingSlots extends React.Component {

    constructor(props) {
        super(props);
        this.db = new Database();
        this.state = {
            bslots: "",
            bstat: "Loading...",
            filterSlots: '',
            settings: [],
            Booked: [0,0]
        }
        this.fetchdata();
        // this.fetchdata = this.fetchdata().bind(this).
    }

    fetchdata = async (filter = {}) => {
        const data = await this.getData(filter);

        this.data = await data.json();
        
        this.setState({
            bslots: this.data,
            Booked: [this.data.sum('Booked'),this.data.sum('Available')],
            bstat: this.data.length > 0 ? '' : 'No Data Available'
        });

    }

    getData = async (filter = {}) => {
        if(filter === {}){
            let ctime = this.props.app.formattedTime(new Date().toLocaleString(false, { timeZone: 'Asia/Kolkata' }), 0);
            let cdate = new Date();
            let cmon = parseInt(cdate.getMonth()) + 1;
            
            cdate = [
            cdate.getFullYear(),
            cmon < 10 ? '0' + cmon : cmon,
            cdate.getDate() < 10 ? '0' + cdate.getDate() : cdate.getDate()
            ]

            ctime = cdate.join('-') + 'T' + ctime + '.' + new Date().toISOString().split('.')[1];
            filter = { 
                "$expr": 
                { "$gt": 
                    [
                        { "$dateFromString": { "dateString": "$_id" } },
                        { "$dateFromString": { "dateString": ctime } }
                    ]
                }
            }
        }
        let token = this.props.user.accessToken;
        return await this.props.app._getmongo(token, 'sortfind', 'AvailableSlots', filter, { 'Date': 1, 'Slot': 1 }, 50);

    }

    async componentDidMount() {
        this.fetchdata({
            $expr: {
              $gte: [
                { $dateFromString: {
                    dateString: '$_id'
                } },
                { "$dateFromString": { "dateString": (new Date()) } }
                
              ]
            }
          });
        
        this.settings = await this.props.app.getSettings();
        this.setState({
            settings: this.settings
        })
        //  window.addEventListener('load', this.fetchdata, true);
        //
    }

    

    componentWillUnmount() {
        // window.removeEventListener('load', this.fetchdata);
    }

    dateConvert = (date = new Date()) => {
        let m = parseInt(date.getMonth()) + 1;
        let d = parseInt(date.getDate());

        date = date.getFullYear() + '-' + (m < 10 ? '0' + (m) : (m)) + '-' + (d < 10 ? '0' + (d) : (d));
        
        return date;
    }

    dateRange = (start, end) => {
        start = new Date(start); end = new Date(end);
        start = start.getTime();
        end = end.getTime();

        var dates = [];
        for (let loop = start; loop <= end; loop += 86400000) {
            let dt = this.dateConvert(new Date(loop));
            dates.push(dt);
        }
        return dates;
    }

    timeslots = (h = 9, mx = 17, skip = []) => {
        let slots = [];
        h = parseInt(h);
        mx = parseInt(17);
        for (let i = h; i <= mx; i++) {
            let t = i < 10 ? '0' + i : i;
            for (let m = 0; m < 60; m += 15) {
                let ft = t + ":" + (m < 10 ? '0' + m : m);
                if (!skip.includes(ft)) {
                    slots.push(ft);
                }
            }
        }

        return slots;
    }

    addSlots = (e) => {
        e.preventDefault();
        const { slotdate, slotdatetill, slottime, capacity } = e.target.elements;
        let token = this.props.user.accessToken;

        const dates = this.dateRange(slotdate.value, slotdatetill.value);
        const cap = capacity.value.length < 1 ? (this.state.settings.DefaultCapacity !== undefined ? this.state.settings.DefaultCapacity : 10) : capacity.value;
        let data = [];
        let idx = 0;
        var slots = this.getSelected(slottime);
        dates.forEach((dt, index) => {

            
            slots.forEach((x, i) => {
                var _k = dt + ' ' + x;
                data[idx] = {
                    _id: _k,
                    Date: dt,
                    Slot: x,
                    Available: parseInt(cap),
                    Booked: 0,
                    checkIn: 0,
                    checkOut: 0
                }
                idx++;
            })



        });
        

        this.props.app._setmongo(token, 'uniqueinsert', 'AvailableSlots', data).then((res) => {
            this.fetchdata();
            alert('New Slot Added / Updated');
        }).catch((err) => {
            
            alert('Error! Please contact Administrator!');
        });

        //this.fetchdata();
    }

    delslot = async (e) => {
        e.preventDefault();
        let token = this.props.user.accessToken;
        const val = e.target.getAttribute('data');
        let confirm = window.confirm('Do you want to delete the slot: ' + val);
        if (confirm) {
            let checkslot = this.props.app._getmongo(token, 'findone', 'AvailableSlots', { _id: val });
            checkslot = await checkslot.ok ? checkslot.json() : checkslot;
            

            if (checkslot.Booked > 0) {
                confirm = window.confirm(`Slot ${val} has ${checkslot.Booked} bookings.`);
            }
            if (confirm) {
                this.props.app._setmongo(token, 'deleteone', 'AvailableSlots', {}, { _id: val }).then((res) => {
                    
                    window.alert(`Slot ${val} has been removed`);
                    this.filterSlots(e);
                    /*this.fetchdata({
                        $expr: {
                          $gte: [
                            { $dateFromString: {
                                dateString: '$_id'
                            } },
                            { "$dateFromString": { "dateString": (new Date()) } }
                            
                          ]
                        }
                      });*/
                }).catch((err) => {
                    
                });
            }



            /* this.db._remove('AvailableSlots', val).then((res)=>{
                 
                 this.fetchdata();
             }).catch(err=>*/

        }
        //alert(.getAttribute('data'));
    }

    getSelected = (e) => {
        return Array.from(e.selectedOptions, option => option.value);
    }

    resetFltr = async() => {
        this.fetchdata({
            $expr: {
                $gte: [
                { $dateFromString: {
                    dateString: '$_id'
                } },
                { "$dateFromString": { "dateString": (new Date()) } }
                
                ]
            }
        });
    }

    filterSlots = async (e) => {
        e = e;
        let fltr = this.state.filterSlots;
        let slot = fltr.replaceAll(' ', '').split('??');
        
        fltr = slot[0].replaceAll(' ', '').split(',');
        slot = slot[1] !== undefined ? slot[1] : false;
        let qry = {};
        let field;
        fltr = fltr.map((item) => {
            item = item.split('::');
            
            return item[1] !== undefined ? { from: item[0], to: item[1] } : item[0];
        });

        fltr.forEach((item) => {
            if (typeof (item) === 'object') {
                if (qry['$and'] === undefined) {
                    qry['$and'] = [];
                }
                if(slot){
                    qry['$and'].push({Slot: slot});
                }
                field = "$Date";
                qry['$and'].push(
                    {
                        $expr:
                        {
                            $gte:
                                [
                                    { "$dateFromString": { "dateString": field } },
                                    { "$dateFromString": { "dateString": item.from } }
                                ]
                        }
                    });
                field = "$Date";
                qry['$and'].push(
                    {
                        $expr:
                        {
                            $lte:
                                [
                                    { "$dateFromString": { "dateString": field } },
                                    { "$dateFromString": { "dateString": item.to } }
                                ]
                        }
                    }
                );
            }

            if (typeof (item) === 'string') {
                field =  "$Date" ;
                if (qry['$or'] === undefined) {
                    qry['$or'] = [];
                }
                let eq = [
                    { "$dateFromString": { "dateString": field } },
                    { "$dateFromString": { "dateString": item } }
                ];
                if(slot){
                    eq.push({Slot: slot});
                }
                qry['$or'].push(
                    {
                        $expr:
                        {
                            $eq: eq
                                
                        }
                    });
            }

        })
        
        
        this.fetchdata(qry);
    }

    _0 = (n) => {
        return n < 10 ? '0' + n : n;
    }

    render() {
        let bt = this.state.settings.BookingTime !== undefined ? this.state.settings.BookingTime : [9,17];
        let skipslots = this.state.settings.SkipSlots !== undefined ? this.state.settings.SkipSlots : [];
        const timeslots = this.timeslots( bt[0], bt[1], skipslots);
        
        
        return (
            <>
                <Helmet>
                    <title>Booking Slots</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />


                    <body id="page-top"></body>
                </Helmet>
                <div className='row'>
                    <div className="col-md-9">
                        <h1 className="h3 mb-2 text-gray-800">Booking Slots</h1>
                    </div>

                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Add New Slots in Bulk</h6>
                    </div>
                    <div className="card-body">
                        <form id='bookingslot' onSubmit={this.addSlots}>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor='slotdate'>From Date</label>
                                        <input type="date" onFocus={this.props.app.showPicker} min={this.dateConvert()} className="form-control" name='slotdate' id="slotdate" required />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor='slotdate'>Up to Date</label>
                                        <input type="date" onFocus={this.props.app.showPicker} min={this.dateConvert()} className="form-control" name='slotdatetill' id="slotdatetill" required />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor='slotdate'>Booking Slot Time</label>
                                        <select multiple className="form-control" name='slottime' id="slottime" required>
                                            <option value="">Select Time Slot</option>
                                            {timeslots.map((t) => {
                                                return <option value={t}>{t}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor='capacity'>Capacity</label>
                                        <input type="number" className="form-control" name='capacity' id="capacity" defaultValue={(this.state.settings.DefaultCapacity !== undefined ? this.state.settings.DefaultCapacity : 10)} min={0} step={1} />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <button className='btn btn-primary'>Add / Update Slots</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Booking Slot List</h6>
                    </div>
                    <div className="card-body">
                        <div className='input-group mb-3'>
                            <input type='text' onChange={(e) => {
                                this.setState({
                                    filterSlots: e.target.value
                                })
                            }} id="filterSlots" className='form-control' placeholder='Date or Date Range' value={this.state.filterSlots} />
                            <div className="input-group-append">
                                <button onClick={this.filterSlots} className="input-group-text bg-primary text-white">Find</button>
                            </div>

                        </div>
                        {this.state.filterSlots.length > 3 ?
                        <div className='text-right'><button className='btn btn-dark' onClick={this.resetFltr}>Reset</button></div>: <></>}
                        <strong>Date Filters: (Double tap on blue badges)</strong>
                        <pre>
                            Single date YYYY-MM-DD <i className='fa fa-arrow-right'></i> <span className='badge badge-info' onDoubleClick={(e) => {
                                this.setState({
                                    filterSlots: `${this.props.app.getDate()}`
                                })
                            }}>(Double Tap/click for today)</span><br />
                            Single date slot YYYY-MM-DD::YYYY-MM-DD??HH:MM <i className='fa fa-arrow-right'></i> <span className='badge badge-info' onDoubleClick={(e) => {
                                this.setState({
                                    filterSlots: `${this.props.app.getDate()}::${this.props.app.getDate('Y-M-D', false, { day: 15 })}??${this._0(new Date().getHours())}:30`
                                })
                            }}>(Double Tap/click for all slots in a month)</span>
                            <br  />
                            [,] Multiple date YYYY-MM-DD,YYYY-MM-DD <i className='fa fa-arrow-right'></i> <span className='badge badge-info' onDoubleClick={(e) => {
                                this.setState({
                                    filterSlots: `${this.props.app.getDate()},${this.props.app.getDate('Y-M-D', false, { day: 1 })}`
                                })
                            }}>(Double Tap/click for today &amp; tomorrow)</span><br />
                            [::] Range date YYYY-MM-DD::YYYY-MM-DD <i className='fa fa-arrow-right'></i> <span className='badge badge-info' onDoubleClick={(e) => {
                                this.setState({
                                    filterSlots: `${this.props.app.getDate()}::${this.props.app.getDate('Y-M-D', false, { day: 7 })}`
                                })
                            }}>(Double Tap/click for from today to 7 days)</span> OR <span className='badge badge-info' onDoubleClick={(e) => {
                                const dts = this.props.app.getmonthDates();
                                this.setState({
                                    filterSlots: `${dts.today}::${dts.last}`
                                })
                            }}>(Double Tap/click for current month)</span><br />
                            Mix date YYYY-MM-DD,YYYY-MM-DD::YYYY-MM-DD<i className='fa fa-arrow-right'></i> <span className='badge badge-info' onDoubleClick={(e) => {
                                const dts = this.props.app.getmonthDates();
                                this.setState({
                                    filterSlots: `${dts.today},${this.props.app.getDate('Y-M-D', false, { day: 3 })}::${dts.last}`
                                })
                            }}>(Double Tap/click for current month except tomorrow and the day after tomorrow</span><br />
                        </pre>
                        <div className="table-responsive">
                            
                        <div className='text-right'>
                            {<span className='badge badge-info'><h5><strong>Booked: </strong>{this.state.Booked[0]}/{this.state.Booked[1]}</h5></span>}
                        </div>
                            {
                                this.state.bslots !== undefined && typeof (this.state.bslots) === 'object' ?
                                    (<>
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date Slot</th>
                                                    <th>Available</th>
                                                    <th>Booked</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.entries(this.state.bslots).map(([key, data], index) => {

                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{data.Date + ' ' + data.Slot}</td>
                                                                <td>{data.Available}</td>
                                                                <td>{data.Booked}</td>
                                                                <td><button className='btn btn-danger' title='Delete Slot' onClick={this.delslot} data={data.Date + ' ' + data.Slot}>X</button></td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </>) :
                                    (<>{this.state.bstat}</>)
                            }
                            <div className='text-right'>
                            {<span className='badge badge-info'><h5><strong>Booked: </strong>{this.state.Booked[0]}/{this.state.Booked[1]}</h5></span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default BookingSlots